import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import { CSVLink } from "react-csv";
import moment from 'moment';

const URL = "https://dev.arkmnetwork.com/api/app/payout/report";

const TwoTeamPayout = () => {
    const [item, setItem] = useState([]);
    const [access_token, setaccess_token] = useState('');
    const [filteredItem, setFilteredItem] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);

    
    const [username, setUsername] = useState();
    const [from_date, setFrom_date] = useState();
    const [to_date, setTo_date] = useState();

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getItem = async (page) => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}?page=${page}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async (page) => {
        let response = await getItem(page);
        console.log(response);
        setItem(response.data.payouts.data);
        // setFilteredItem(response.data.payouts.data);
        setLimit(response.data.payouts.per_page);
        setTotalPages(response.data.payouts.last_page);

        if (username && from_date && to_date) {
            setFilteredItem(
                response.data.payouts.data.filter(item => item.username === username && item.from_date === from_date &&  item.to_date === to_date))
        }
        else if (username) {
            setFilteredItem(
                response.data.payouts.data.filter(item => item.username === username))
        }
        else if (from_date && to_date) {
            setFilteredItem(
                response.data.payouts.data.filter(item => item.from_date === from_date && item.to_date === to_date))
        }
        else{
            setFilteredItem(response.data.payouts.data);
        }
    }

    const columns = [
        {
            name: <h6>User</h6>,
            selector: row => row.username,
            sortable: true,
        },
        {
            name: <h6>Date</h6>,
            selector: row => row.created_at,
            sortable: true,
            cell: (row) => (
                <div className="text-danger">
                    {moment(row.created_at).format("MMMM Do YYYY")}
                </div>
            ),
        },
        {
            name: <h6>Business</h6>,
            selector: row => <div><b>$  </b>{parseFloat(row.left_business).toFixed(2)}</div>,
            sortable: true,
        },
        {
            name: <h6>Previous Balance</h6>,
            selector: row => <div><b>$  </b>{parseFloat(row.prev_left_balance).toFixed(2)}</div>,
            sortable: true,
        },
        {
            name: <h6>Business</h6>,
            selector: row => <div className="text-info"><b>$  </b>{parseFloat(row.right_business).toFixed(2)}</div>,
            sortable: true,
        },
        {
            name: <h6>Previous Balance</h6>,
            selector: row => <div><b>$  </b>{parseFloat(row.prev_right_balance).toFixed(2)}</div>,
            sortable: true,
        },
        {
            name: <h6>Left Balance</h6>,
            selector: row => <div><b>$  </b>{parseFloat(row.left_balance).toFixed(2)}</div>,
            sortable: true,
        },
        {
            name: <h6>Right Balance</h6>,
            selector: row => <div><b>$  </b>{parseFloat(row.right_balance).toFixed(2)}</div>,
            sortable: true,
        },
        {
            name: <h6>Mathching Business</h6>,
            selector: row => <div><b>$  </b>{parseFloat(row.total_payout).toFixed(2)}</div>,
            sortable: true,
        },
        {
            name: <h6>Amount</h6>,
            selector: row => <div className="text-warning"><b>$  </b>{parseFloat(row.commission).toFixed(2)}</div>,
            sortable: true,
        },
        {
            name: <h6>Total Days</h6>,
            selector: row => row.total_days,
            sortable: true,
        },
        {
            name: <h6>Paid Days</h6>,
            selector: row => row.count_day,
            sortable: true,
        },

    ]

    const handleusername=(data)=>{
        setUsername(data)
    }

    const handlefrom_date=(data)=>{
        setFrom_date(parseInt(data))
    }

    const handleto_date=(data)=>{
        setTo_date(parseInt(data))
    }

    useEffect(() => {

        getdata();

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    useEffect(() => {
        getdata(currentPage);
    }, [currentPage,username,from_date,to_date]);

    return (
        <>
            <UserAdmin
                card1={
                    <div className="card ml-4 mr-4 mb-5 mt-5">
                        <div className="card-header">Two Team Payout</div>
                        <form className="ml-5 mr-5 mt-4 mb-5">
                            <div className="form-row">
                                <div className="form-group col-md-6">

                                    <label for="inputEmail4">Username</label>
                                    <input type="text"
                                     name="username" 
                                     onChange={(event) => handleusername(event.target.value)}  
                                     className="form-control" id="inputEmail4" placeholder="Username" />

                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Payout Date Between</label>
                                    <div className="d-flex">

                                        <input type="date"
                                         className="form-control mr-1"  
                                         onChange={(event) => handlefrom_date(event.target.value)} 
                                         id="inputEmail4" name="from_date" placeholder="Start Date" />

                                        <input type="date"
                                         className="form-control ml-1"  
                                         onChange={(event) => handleto_date(event.target.value)} 
                                         id="inputEmail4" name="to_date" placeholder="End Date" />
                                         
                                    </div>
                                </div>
                                {/* <div className="form-group col-md-12 mt-2">
                                    <button type="button"  className="btn btn-dark btn-block mt-4">Search</button>
                                </div> */}
                            </div>
                        </form>
                    </div>
                }
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                        <DataTable
                            title={<h5 className="mt-4">Two Team Payout Report</h5>}
                            columns={columns}
                            data={filteredItem}
                            pagination
                            paginationServer
                            paginationTotalRows={totalPages * limit}
                            paginationPerPage={limit}
                            onChangePage={handlePageChange}

                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            selectableRow
                            selectableRowsHighlight
                            highlightOnHover
                            actions={
                                <CSVLink data={filteredItem} filename="Transactions.csv">
                                    <button className="btn btn-md btn-dark mt-3">Export</button>
                                </CSVLink>
                            }
                        />
                    </div>
                }
            />

        </>
    )
}
export default TwoTeamPayout;
