import React from "react";

const Managetransaction = (prop) => {
    const {handlesource, handlestatus} = prop

    return (
        <>
            <div className="card ml-4 mr-4 mb-5 mt-5">
                <div className="card-header">Transaction</div>
                <form className="ml-5 mr-5 mt-4 mb-4">
                    {/* <div className="form-row">
                        <div className="form-group col-md-6">
                            <label for="inputEmail4">Username</label>
                            <input type="text" className="form-control" id="inputEmail4" placeholder="Username" />
                        </div>
                        <div className="form-group col-md-6">
                        <label for="inputEmail4">Income Between</label>
                            <div className="d-flex">
                                <input type="date" className="form-control" id="inputEmail4" placeholder="Keyword" />
                                <input type="date" className="form-control" id="inputEmail4" placeholder="Keyword" />
                            </div>
                        </div>
                    </div> */}
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label for="inputEmail4">Type</label>
                            <select id="inputState" className="form-control" onChange={(event) => handlesource(event.target.value)}>
                                <option value="" selected>All</option>
                                <option value="ROI">SOI REWARD</option>
                                <option value="SPONSOR">REFERRAL REWARD</option>
                                <option value="BINARY">MATCHING REWARD</option>
                                <option value="KICK STARTER">KICK START REWARD</option>
                                <option value="LIQUIDITY POOL">LIQUIDITY POOL REWARD</option>
                                <option value="GAMING">GAMING REWARD</option>
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label for="inputPassword4">Encashment</label>
                            <select id="inputState" className="form-control" onChange={(event) => handlestatus(event.target.value)}>
                                <option  value="" selected>All</option>
                                <option value="0">Paid</option>
                                <option value="1">Unpaid</option>
                            </select>
                        </div>
                    </div>        
                    {/* <button type="button" class="btn btn-dark btn-block">Search</button> */}

                </form>
            </div>

        </>
    )
}
export default Managetransaction;
