import React from "react";
import { CSVLink } from "react-csv";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState,useEffect } from "react";
import { useHistory } from "react-router-use-history";

import axios from 'axios';
const URL ="https://dev.arkmnetwork.com/api/app/wallet/transaction";
const AccountStatement = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getItem = async (page) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get(`${URL}?page=${page}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    const getdata = async (page) => {
        let response = await getItem(page);
        console.log(response);
        setItem(response.data.transactions.data);
        setFilteredItem(response.data.transactions.data);
        console.log(item)
        setLimit(response.data.transactions.per_page);
        setTotalPages(response.data.transactions.last_page);
    }

    const columns = [
        {
            name: <h6>#Transaction</h6>,
            selector: row => row.code,
            sortable: true,
        },
        {
            name: <h6>Date</h6>,
            selector: row => <div className="text-warning">{(row.created_at)}</div>,
            sortable: true,
        },
        {
            name: <h6>Type</h6>,
            selector: row => row.type,
            sortable: true,
        },
        {
            name: <h6>Source</h6>,
            selector: row => row.source,
            sortable: true,
        },
        {
            name: <h6>Wallet</h6>,
            selector: row =><div className="text-primary">{(row.wallet)}</div> ,
            sortable: true,
        },
        {
            name: <h6>Description</h6>,
            selector: row => row.description,
            sortable: true,
        },
        {
            name: <h6>Status</h6>,
            selector: row => <div className="text-success">{(row.status)}</div>,
            sortable: true,
        },
        {
            name: <h6>Amount ($)</h6>,
            selector: row => row.amount,
            sortable: true,
            cell: (row) => (
                <div className="text-warning">
                    $  {parseFloat(row.amount).toFixed(2) || '0.00'}
                </div>
            ),
        },
        // {
        //     name: "Action",
        //     cell: (row) => <button className="btn btn-dark" onClick={() => alert(row.id)}>Edit</button>
        // },
    ]

    // const csvLink = {
    //     filename: "Account Statement.csv",
    //     headers: columns,
    //     data: item
    // }

    useEffect(() => {
        setTimeout(() => {
            getdata();
        }, 1000);

        // if(access_token == false ){
        //     history.push("/login");
        // }

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    useEffect(() => {
        getdata(currentPage);
    }, [currentPage]);

    return (
        <>
            <UserAdmin

                // card1={
                //     <CSVLink {...csvLink} className="btn btn-md btn-dark mt-3">Export</CSVLink>
                // }

                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                        <DataTable
                            title={<h5 className="mt-4">Account Statement</h5>}
                            columns={columns}
                            data={filteredItem}
                            pagination
                            paginationServer
                            paginationTotalRows={totalPages * limit}
                            paginationPerPage={limit}
                            onChangePage={handlePageChange}

                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            selectableRow
                            selectableRowsHighlight
                            highlightOnHover
                            actions={
                                <CSVLink data={filteredItem} filename="Account_Statement.csv">
                                <button className="btn btn-md btn-dark mt-3">Export</button>
                                </CSVLink>
                            }
                        />
                    </div>
                }
            />

        </>
    )
}
export default AccountStatement;