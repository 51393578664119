import React from "react";
import Login from "../Components/Login";

const Signin = () => {
    return (
        <>
            <Login/>
        </>
    )
}
export default Signin;