import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import { CSVLink } from "react-csv";
import PurchasePackage from "../Components/PurchasePackage";
const URL = "https://dev.arkmnetwork.com/api/app/subscription";

const PurchaseYourPackage = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        // "Content-Type": "application/json",
                        // "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }
    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.subcriptions.data);
        setFilteredItem(response.data.subcriptions);
        console.log(item)

    }

    const columns = [
        {
            name: <h6>#ID</h6>,                   
            selector: row => row.invoice_id,               
            sortable: true,
        },
        {
            name: <h6>User</h6>,
            selector: row => <div className="text-info">{(row.sub_user)}</div>,               
            sortable: true,
        },
        {
            name: <h6>Subscription done By</h6>,
            selector: row =>  <div className="text-warning">{(row.subdoneby_user)}</div>,               
            sortable: true,
        },
        {
            name: <h6>Package Name</h6>,
            selector: row => row.name,            
            sortable: true,
        },
        {
            name: <h6>Payment ID</h6>,
            selector: row => row.transaction_id,          
            sortable: true,
        },
        {
            name: <h6>Price</h6>,
            selector: row => <div className="text-info"><b>{parseFloat(row.price).toFixed(2)}</b><b>  USD</b></div>,       
            sortable: true,
        },
        {
            name:<h6>Total Income Limit</h6>,
            selector: row => <div className="text-primary"><b>USD  </b><b>{parseFloat(row.total_income_limit).toFixed(0)}</b></div>,          
            sortable: true,
        },
        {
            name: <h6>Paid Total Income</h6>,
            selector: row =><div><b>USD  </b><b>{parseFloat(row.total_paid_income).toFixed(2)}</b></div>,            
            sortable: true,
        },
        {
            name: <h6>Total ROI Income Limit</h6>,
            selector: row =><div className="text-info"><b>USD  </b><b>{parseFloat(row.total_roi_income_limit).toFixed(0)}</b></div>,            
            sortable: true,
        },
        {
            name: <h6>Paid ROI Income</h6>,
            selector: row => <div><b>USD  </b><b>{parseFloat(row.paid_roi_income).toFixed(2)}</b></div>,            
            sortable: true,

        },
        {
            name: <h6>Activation Date</h6>,
            selector: row => row.created_at,            
            sortable: true,

        },
        // {
        //     name: "Action",
        //     cell: (row) => <button className="btn btn-dark" onClick={() => alert(row.id)}>Edit</button>
        // },
    ]
    useEffect(() => {

        // setTimeout(() => {
        //     getdata();
        // }, 5000);
        
        // if (item.length && filteredItem.length === 0){
            getdata();
        // }
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);
    
    return (
        <>
            <UserAdmin
                card1={
                    <PurchasePackage/>
                }
                card2={
                     <div className="col-12 col-md-12 card ml-4 mri mt-4">
                    <DataTable
                        title={<h5 className="mt-4">Package Activation History</h5>}
                        columns={columns}
                        data={item}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="600px"
                        selectableRow
                        selectableRowsHighlight
                        highlightOnHover
                        actions={
                            <CSVLink data={item} filename="Package_History.csv">
                                <button className="btn btn-md btn-dark mt-3">Export</button>
                            </CSVLink>
                        }
                    />
                    </div>
                } />
           
        </>
    )
}
export default PurchaseYourPackage;
