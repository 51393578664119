import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import { CSVLink } from "react-csv";
import Managetransaction from "../../src/Main/admincomponents/Managetransaction";
import moment from 'moment';

const URL = "https://dev.arkmnetwork.com/api/app/report/comission";
// const URL2 = "https://dev.arkmnetwork.com/api/app/report/comission?page="+{i};

const Transactions = () => {
    // const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);

    const [source, setSource] = useState();
    const [status, setStatus] = useState();
    
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getItem = async (page) => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}?page=${page}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }
    const getdata = async (page) => {
        let response = await getItem(page);
        console.log(response);
        setLimit(response.data.incomes.per_page);
        setTotalPages(response.data.incomes.last_page);

        if (source && status) {
            setFilteredItem(
                response.data.incomes.data.filter(item => item.source === source && item.encashment === status))
        }
        else if (source) {
            setFilteredItem(
                response.data.incomes.data.filter(item => item.source === source))
        }
        else if (status) {
            setFilteredItem(
                response.data.incomes.data.filter(item => item.status === status))
        }
        else{
            setFilteredItem(response.data.incomes.data);
        }
    }

    const columns = [
        {
            name: <h6>Date</h6>,
            selector: row => row.created_at,
            sortable: true,
            cell: (row) => (
                <div>
                    {moment(row.created_at).format("MMMM Do YYYY")}
                </div>
            ),
        },
        {
            name: <h6>Source</h6>,
            selector: row => row.source,
            sortable: true,
            cell: (row) => (
                <div>
                    {row.source == 'ROI' && 'SOI REWARD'}
                    {row.source == 'SPONSOR' && 'REFERRAL REWARD'}
                    {row.source == 'BINARY' && 'MATCHING REWARD'}
                    {row.source == 'KICK STARTER' && 'KICK START REWARD'}
                    {row.source == 'LIQUIDITY POOL' && 'LIQUIDITY POOL REWARD'}
                    {row.source == 'GAMING' && 'GAMING REWARD'}
                </div>
            ),
        },
        {
            name: <h6>Description</h6>,
            selector: row => row.description,
            sortable: true,
        },
        {
            name: <h6>Amount</h6>,
            selector: row => row.amount + " USD",
            sortable: true,
            cell: (row) => (
                <div className="text-success">
                    $  {parseFloat(row.amount).toFixed(2) || '0.00'}
                </div>
            ),

        },
        {
            name: <h6>Status</h6>,
            selector: row => <div className="text-warning">{(row.encashment === 1 && "Paid" || "Unpaid")}</div>,
            sortable: true,
        }
    ]

    const handlesource=(data)=>{
        setSource(data)
    }

    const handlestatus=(data)=>{
        setStatus(parseInt(data))
    }

    useEffect(() => {

        if (filteredItem.length === 0) {
            getdata();
        }

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    useEffect(() => {
        getdata(currentPage);
    }, [currentPage,source,status]);

    // useEffect(() => {
    //     handleFilter(source,status);
    // }, [source,status]);


    console.log("source",source,status )

    return (
        <>

            <UserAdmin
                card1={
                    <Managetransaction
                        handlesource={handlesource}
                        handlestatus={handlestatus}
                    />
                }
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                        <DataTable
                            title={<h5 className="mt-4">Transactions</h5>}
                            columns={columns}
                            data={filteredItem}
                            pagination
                            paginationServer
                            paginationTotalRows={totalPages * limit}
                            paginationPerPage={limit}
                            onChangePage={handlePageChange}

                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            selectableRow
                            selectableRowsHighlight
                            highlightOnHover
                            actions={
                                <CSVLink data={filteredItem} filename="Transactions.csv">
                                    <button className="btn btn-md btn-dark mt-3">Export</button>
                                </CSVLink>
                            }
                        />
                    </div>
                }
            />

        </>
    )
}
export default Transactions;
