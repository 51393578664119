import React from "react";
import UserAdmin from "./Dashboard/UserAdmin";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
const URL = "https://dev.royaltrading.world/api/app/profile";

const Profile2 = () => {
    const [access_token, setaccess_token] = useState('');
    const [users, setUsers] = useState([]);
    const [all_data, setAllData] = useState({});
    const [profile_pic, setProfile_pic] = useState();
    const [firstname, setfirstname] = useState();
    const [middlename, setmiddlename] = useState();
    const [lastname, setlastname] = useState();
    const [username, setusername] = useState();
    const [email, setemail] = useState();
    const [address, setaddress] = useState();
    const [country, setcountry] = useState();
    const [state, setstate] = useState();
    const [city, setcity] = useState();
    const [pincode, setpincode] = useState();
    const [phone, setphone] = useState();
    const [secret, setsecret] = useState();
    const [spassword, setspassword] = useState();
    const [old_password, setold_password] = useState();
    const [password, setpassword] = useState();
    const [password_confirmation, setpassword_confirmation] = useState();
    const [Spassword, setSpassword] = useState();
    const [spassword_confirmation, setspassword_confirmation] = useState();
    const [coin_name, setCoin_name] = useState({});
    const [coin_address, setCoin_address] = useState();
    const [spassword2, setSpassword2] = useState();
    const [emailVerify, setemailVerify] = useState();
    const [withdrawalVerify, setwithdrawalVerify] = useState();
    const [Result, setResult] = useState("");
    const [Country, setCountry] = useState([]);
    const [packk, setPack] = useState([]);

    const pack = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get("https://dev.arkmnetwork.com/api/app/subscription",
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    const packa = async () => {
        let response = await pack();
        console.log(response)
        setPack(response.data);

    }

    const getUser = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get("https://dev.arkmnetwork.com/api/app/profile",
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    const getAllUsers = async () => {
        let response = await getUser();
        console.log(response)
        setUsers(response.data.user.profile);

        setAllData(response.data.user);
        setCountry(response.data.all_country)
        setCoin_name(response.data.user.profile.withdrawal_coin)
    }

    const user1 = async (data) => {
        let access_token = localStorage.getItem('access_token');
        const URL3 = "https://dev.arkmnetwork.com/api/app_profile/"+ `${(all_data?.username)}` + "/change-personal-information";
        try {
            var result = await axios.post(`${URL3}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                },
            })
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function save() {
        let item2 = { firstname, middlename, lastname, username, email, address, country, state, city, pincode, phone, spassword };
        console.log(item2);
        let result = await user1(item2)

        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('firstname')) {
                    toast.info(result.errors.firstname[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('middlename')) {
                    toast.info(result.errors.middlename[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('lastname')) {
                    toast.info(result.errors.lastname[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('username')) {
                    toast.info(result.errors.username[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('email')) {
                    toast.info(result.errors.email[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('address')) {
                    toast.info(result.errors.address[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('country')) {
                    toast.info(result.errors.country[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('state')) {
                    toast.info(result.errors.state[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('city')) {
                    toast.info(result.errors.city[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('pincode')) {
                    toast.info(result.errors.pincode[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('phone')) {
                    toast.info(result.errors.phone[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('spassword')) {
                    toast.info(result.errors.spassword[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const user = async (data) => {
        let access_token = localStorage.getItem('access_token');
        const URL2 = "https://dev.arkmnetwork.com/api/app_profile/" + `${(all_data?.username)}` + "/change-picture";
        try {
            var result = await axios.post(`${URL2}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                },
            })
            
            console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    // const convert = (e) => {
    //     let file = e.target.file[0];
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setProfile_pic(reader.result.toString());
    //         console.log(profile_pic)
    //     };
    //     reader.readAsDataURL(file)
    // };

    async function sent() {

        // const fileToBase64 = (filename, filepath) => {
        //     return new Promise(resolve => {
        //       var file = new File([filename], filepath);
        //       var reader = new FileReader();
        //       // Read file content on file loaded event
        //       reader.onload = function(event) {
        //         resolve(event.target.result);
        //       };
        //       // Convert data to base64
        //       reader.readAsDataURL(file);
        //     });
        //   }
        //   fileToBase64(profile_pic,filepath).then(result => {
        //     setResult(result);
        //     console.log(Result);
        //   }); 

        // let item = { profile_pic };
        
        console.log(profile_pic);
        let result = await user(profile_pic)

        if (result.status == true) {

            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.log(profile_pic)

        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('profile_pic')) {
                    toast.info(result.errors.profile_pic[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const user3 = async (data) => {
        let access_token = localStorage.getItem('access_token');
        const URL5 = "https://dev.arkmnetwork.com/api/app_profile/" + `${(all_data?.username)}` + "/change-2fa";
        try {
            var result = await axios.post(`${URL5}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                },
            })
            console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function sent3() {
        let item4 = { secret, spassword };
        console.log(item4);
        let result = await user3(item4)


        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('secret')) {
                    toast.info(result.errors.secret[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.data.errors.hasOwnProperty('spassword')) {
                    toast.info(result.errors.spassword[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const user4 = async (data) => {
        let access_token = localStorage.getItem('access_token');
        const URL6 = "https://dev.arkmnetwork.com/api/app_profile/" + `${(all_data?.username)}` + "/change-password";
        try {
            var result = await axios.post(`${URL6}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                },
            })
            console.log(result)
            return result.data;
        } catch (errors) {
            console.log(errors)
        }
    }

    async function sent4() {
        let item5 = { old_password, password, password_confirmation };
        console.log(item5);
        let result = await user4(item5)

        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('old_password')) {
                    toast.info(result.errors.old_password[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.data.errors.hasOwnProperty('password')) {
                    toast.info(result.errors.password[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('password_confirmation')) {
                    toast.info(result.errors.password_confirmation[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const user5 = async (data) => {
        let access_token = localStorage.getItem('access_token');
        const URL7 = "https://dev.arkmnetwork.com/api/app_profile/" + `${(all_data?.username)}` + "/change-secondary-password";
        try {
            var result = await axios.post(`${URL7}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                },
            })

            console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function sent5() {
        let spassword = Spassword
        let item6 = { old_password, spassword, spassword_confirmation };
        console.log(item6);
        let result = await user5(item6)


        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('old_password')) {
                    toast.info(result.errors.old_password[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('spassword')) {
                    toast.info(result.errors.spassword[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('spassword_confirmation')) {
                    toast.info(result.errors.spassword_confirmation[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const user6 = async (data) => {
        let access_token = localStorage.getItem('access_token');
        const URL8 = "https://dev.arkmnetwork.com/api/app_profile/" + `${(all_data?.username)}` + "/withdraw-setting";
        try {
            var result = await axios.post(`${URL8}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                },
            })

            console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function sent6() {
        let spassword = spassword2
        let item7 = { coin_name, coin_address, spassword };
        console.log(item7);
        let result = await user6(item7)

        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('coin_name')) {
                    toast.info(result.errors.coin_name[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('coin_address')) {
                    toast.info(result.errors.coin_address[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('spassword_confirmation')) {
                    toast.info(result.errors.spassword_confirmation[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const user7 = async (data) => {
        let access_token = localStorage.getItem('access_token');
        const URL9 = "https://dev.arkmnetwork.com/api/app_profile/" + `${(all_data?.username)}` + "/verification";
        try {
            var result = await axios.post(`${URL9}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                },
             
            })

            console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function sent7() {
        let item8 = { emailVerify, withdrawalVerify };
        console.log(item8);
        let result = await user7(item8)


        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('emailVerify')) {
                    toast.info(result.errors.emailVerify[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('withdrawalVerify')) {
                    toast.info(result.errors.withdrawalVerify[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    useEffect(() => {
        getAllUsers();
        packa();
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    return (
        <>
            <div className="container">
                <div className="row">
                    <ToastContainer />
                    <div className="col-lg-3">
                        <div className="card mb-4">
                            <div className="card-body text-center">

                                <img src={`https://dev.arkmnetwork.com/en/avatars/${(users?.avatar)}`} alt="avatar"
                                    className="rounded-circle img-fluid" style={{ width: "150px", height: "150px" }} />
                                <h5 className="my-3 text-md">{(all_data?.username) || " "}</h5>
                                <p className="text-muted mb-1 text-md">{(all_data?.email) || " "}</p>
                                <p className="text-muted mb-4 text-md">{(users?.mobile_no) || " "}</p>
                                <div className="ml-2">
                                    <p className="text-muted mb-1 text-left text-md"><b>Package  : </b> {(packk?.active_package)}</p>
                                    <p className="text-muted mb-1 text-left text-md"><b>Joined On : </b>{moment(all_data?.created_at).format("MMMM Do YYYY")}  </p>
                                    {/* <p className="text-muted mb-1 text-left"><b>Activated On : </b>  {(all_data?.rank_name)}</p> */}
                                    <p className="text-muted mb-1 text-left text-md"> <b>Email : </b>{((all_data?.status) === 1) ? "Active" : "Inactive"}</p>
                                    <p className="text-muted mb-3 text-left text-md"> <b>Withdrawal Status : </b>{((all_data?.status) === 1) ? "Active" : "Inactive"}</p>
                                </div>
                                <div className="d-flex justify-content-center mb-2">
                                    <Link to="/deposit" type="button" className="btn btn-dark">Deposit</Link>
                                    <Link to="/withdraw" type="button" className="btn btn-outline-dark ms-1 ml-2">Withdraw</Link>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-body text-center">
                                <div className="mb-3">
                                    <div className="ml-1">
                                        <p className="text-muted text-left mb-2"><b> Upload Profile Picture </b></p>
                                    </div>
                                    <div className="d-flex justify-content-center mb-2">
                                        {/* {profile_pic ? (<img src={profile_pic} />) : */}
                                            <input className="btn btn-secondary btn-block mb-1" id="formFileMd" type="file" name="profile_pic"
                                                // value={profile_pic}
                                                onChange={(e) => setProfile_pic(e.target.files[0])} />
                                        {/* } */}

                                    </div>
                                    <div className="d-flex justify-content-center mb-2">
                                        <button type="button" onClick={sent} className="btn btn-dark btn-block">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-9">
                        <nav>
                            <div className="nav nav-tabs md-tabs" id="nav-tab" role="tablist">
                                <a className=" btn btn-outline-dark ml-1" id="nav-basic-info-tab" data-toggle="tab" href="#nav-basic-info" role="tab"
                                    aria-controls="nav-basic-info" aria-selected="true">Basic Info</a>

                                <a className=" btn btn-outline-dark ml-1" id="nav-security-tab" data-toggle="tab" href="#nav-security" role="tab"
                                    aria-controls="nav-security" aria-selected="false">Security</a>

                                <a className="btn btn-outline-dark ml-1" id="nav-password-setting-tab" data-toggle="tab" href="#nav-password-setting" role="tab"
                                    aria-controls="nav-password-setting" aria-selected="false">Password Setting</a>

                                <a className="btn btn-outline-dark ml-1" id="nav-secondary-password-tab" data-toggle="tab" href="#nav-secondary-password" role="tab"
                                    aria-controls="nav-secondary-password" aria-selected="false">Secondary Password</a>

                                <a className="btn btn-outline-dark ml-1" id="nav-withdraw-setting-tab" data-toggle="tab" href="#nav-withdraw-setting" role="tab"
                                    aria-controls="nav-withdraw-setting" aria-selected="false">Withdraw Setting</a>

                                {/* <a className="btn btn-outline-primary ml-1" id="nav-verification-tab" data-toggle="tab" href="#nav-verification" role="tab"
                                    aria-controls="nav-verification" aria-selected="false">Verification</a> */}

                                {
                                    all_data?.username === "admin" &&
                                    <a className="btn btn-outline-dark ml-1" data-toggle="tab" href="#nav-verification" role="tab"
                                        aria-controls="nav-verification" aria-selected="false">Verification</a>
                                }
                            </div>
                        </nav>
                        <div className="tab-content pt-3" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-basic-info" role="tabpanel" aria-labelledby="nav-basic-info-tab">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <h4 className="ml-2"><b>Basic Info</b></h4>
                                        </div>
                                        <hr />
                                        <form>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <label >First Name</label>
                                                    <input type="text" className="form-control" placeholder={(all_data?.first_name) || "First Name"} name="firstname" onChange={(e) => setfirstname(e.target.value)} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label >Middle Name</label>
                                                    <input type="text" placeholder={(all_data?.middle_name) || "Middle Name"} className="form-control" name="middlename" onChange={(e) => setmiddlename(e.target.value)} />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <label >Last Name</label>
                                                    <input type="text" placeholder={(all_data?.last_name) || "Last Name"} className="form-control" name="lastname" onChange={(e) => setlastname(e.target.value)} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <label >Username</label>
                                                    <input type="text" placeholder={(all_data?.username) || "Username"} className="form-control" name="username" onChange={(e) => setusername(e.target.value)} />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-6 mb-2">
                                                    <label>Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder={(all_data?.email) || "Email"}
                                                        name="email"
                                                        onChange={(e) => setemail(e.target.value)} />
                                                </div>
                                                <div className="col-sm-6 mb-2">
                                                    <label>Address</label>
                                                    <input
                                                        type="text"
                                                        placeholder={(users?.address) || "1234 Main St"}
                                                        className="form-control"
                                                        name="address"
                                                        onChange={(e) => setaddress(e.target.value)} />
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6 mb-2">
                                                    <label >Country</label>
                                                    <select className="form-control"
                                                        name="country"
                                                        onChange={(e) => setcountry(e.target.value)}>
                                                        <option>Select Country</option>
                                                        {
                                                            Country.map((country, index) => {
                                                                return (
                                                                    <option key={index} value={country.id}>{country.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>

                                                </div>
                                                <div className="col-sm-6 mb-2">
                                                    <label for="inputState">State</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={(users?.state) || "state"}
                                                        name="state"
                                                        onChange={(e) => setstate(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6 mb-2">
                                                    <label>City</label>
                                                    <input
                                                        type="text"
                                                        placeholder={(users?.city) || "City"}
                                                        className="form-control"
                                                        name="city"
                                                        onChange={(e) => setcity(e.target.value)} />

                                                </div>
                                                <div className="col-sm-6 mb-2">
                                                    <label>Pin Code</label>
                                                    <input type="text"
                                                        placeholder={(users?.pin_code) || "Pin Code"}
                                                        className="form-control" name="pincode" onChange={(e) => setpincode(e.target.value)} />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-6 mb-2">
                                                    <label>Contact Number</label>
                                                    <input type="text"
                                                        placeholder={(users?.mobile_no) || "Contact Number"}
                                                        className="form-control" name="phone" onChange={(e) => setphone(e.target.value)} />

                                                </div>
                                                <div className="col-sm-6 mb-2">
                                                    {
                                                        all_data?.spassword != "" && all_data?.spassword != null &&
                                                        <div >
                                                            <label >Your Secondary Password</label>
                                                            <input type="text" className="form-control mb-3" placeholder="Your Secondary Password" name="spassword" onChange={(e) => setspassword(e.target.value)} />
                                                        </div>
                                                    }
                                                    <p>*Click Save to Save your Basic Info</p>
                                                    <button onClick={save} type="button" className="btn btn-dark btn-block">Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-security" role="tabpanel" aria-labelledby="nav-security-tab">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <h4 className="ml-2 mt-1"><b>Security</b></h4>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <img src={(users?.two_factor_image_url)} width="280" height="236" />
                                                <p className="btn btn-dark" style={{ marginLeft: "50px" }}>{(users?.two_fa_code)}</p>
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="d-flex mb-2">
                                                    <label className="mt-2">Google 2FA Setting </label>
                                                    <label className="btn btn-dark ml-4 mt-1">Status : {((users?.two_fa_status) === 1) ? " Active" : " Inactive"}</label>
                                                </div>

                                                <label >Enter 2FA Code</label>
                                                <input type="password" className="form-control mb-3" placeholder="Enter 2FA Code" name="secret" onChange={(e) => setsecret(e.target.value)} />
                                                {
                                                    all_data?.spassword != "" && all_data?.spassword != null &&
                                                    <div>
                                                        <label >Your Secondary Password</label>
                                                        <input type="text" className="form-control mb-3" placeholder="Your Secondary Password" name="spassword" onChange={(e) => setspassword(e.target.value)} />
                                                    </div>
                                                }
                                                <button type="submit" className="btn btn-dark btn-block" onClick={sent3}>Enable</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-password-setting" role="tabpanel" aria-labelledby="nav-password-setting-tab">

                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <h4 className="ml-2"><b>Password Setting</b></h4>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-12">

                                                <label >Current Password</label>
                                                <input type="password" className="form-control mb-3" name="old_password"
                                                    onChange={(e) => setold_password(e.target.value)} placeholder="Current Password" />

                                                <label >New Password</label>
                                                <input type="password" className="form-control mb-3" name="password"
                                                    onChange={(e) => setpassword(e.target.value)} placeholder="New Password" />

                                                <label >Re-type New Password</label>
                                                <input type="password" className="form-control mb-3" name="password_confirmation"
                                                    onChange={(e) => setpassword_confirmation(e.target.value)} placeholder="Re-type New Password" />

                                                <button type="submit" onClick={sent4} className="btn btn-dark btn-block mb-3">Change Password</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-secondary-password" role="tabpanel" aria-labelledby="nav-secondary-password-tab">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <h4 className="ml-2"><b>Secondary Password</b></h4>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label >Primary Password</label>
                                                <input type="password" className="form-control mb-3" name="old_password" onChange={(e) => setold_password(e.target.value)} placeholder="Primary Password" />
                                                <label >New Secondary Password</label>
                                                <input type="password" className="form-control mb-3" name="spassword" onChange={(e) => setSpassword(e.target.value)} placeholder="New Secondary Password" />
                                                <label >Re-type New Secondary Password</label>
                                                <input type="password" className="form-control mb-3" name="spassword_confirmation" onChange={(e) => setspassword_confirmation(e.target.value)} placeholder="Re-type New Secondary Password" />
                                                <button onClick={sent5} type="submit" className="btn btn-dark btn-block mb-3"> Update Secondary Password</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-withdraw-setting" role="tabpanel" aria-labelledby="nav-withdraw-setting-tab">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <h4 className="ml-2"><b>Withdraw Setting</b></h4>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label >Withdraw Method</label>
                                                <select className="form-control mb-2" name="coin_name"
                                                    onChange={(e) => setCoin_name(e.target.value)}>
                                                    <option selected>Select Withdraw Method</option>
                                                    <option value={(users?.withdrawal_coin)}>{(users?.withdrawal_coin) || "BNB"}</option>
                                                </select>
                                                <label >Account Address</label>
                                                <input type="text" className="form-control mb-3" name="coin_address" onChange={(e) => setCoin_address(e.target.value)} placeholder="Account Address" />
                                                {
                                                    all_data?.spassword != "" && all_data?.spassword != null &&
                                                    <div>
                                                        <label >Your Secondary Password</label>
                                                        <input type="password" className="form-control mb-3" name="spassword" onChange={(e) => setSpassword2(e.target.value)} placeholder="Your Secondary Password" />
                                                    </div>
                                                }
                                                <button type="submit" onClick={sent6} className="btn btn-dark btn-block mb-3">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-verification" role="tabpanel" aria-labelledby="nav-verification-tab">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <h4 className="ml-2"><b>Verification</b></h4>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label >Email Activation</label>
                                                <select className="form-control mb-2" name="emailVerify" onChange={(e) => setemailVerify(e.target.value)}>
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Inactive</option>
                                                </select>
                                                <label >Withdrawal Status</label>
                                                <select className="form-control mb-3" name="withdrawalVerify" onChange={(e) => setwithdrawalVerify(e.target.value)}>
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Inactive</option>
                                                </select>
                                                <button type="submit" onClick={sent7} className="btn btn-dark btn-block mb-3">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Profile2;