import React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import "../css/referral.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const URL = "https://dev.arkmnetwork.com/api/app/subscription";
const URL2 = "https://dev.arkmnetwork.com/api/app/subscription/store";


const PurchasePackage = () => {
    const [access_token, setaccess_token] = useState('');
    const [data, setData] = useState('');
    const [username, setUsername] = useState({});
    const [investment_amount, setInvestment_amount] = useState("");
    const [currency1, setCurrency1] = useState();
    const [deposit, setDeposit] = useState({});
    const [payment_method, setpayment_method] = useState({});

    const card = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    const getAllUsers = async () => {
        let response = await card();
        setData(response.data.packages);
        setDeposit(response.data);
    }

    const user = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL2}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                },
            })
            console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function sent() {
        let item = { username, investment_amount, currency1 };
        item.currency1 = 'deposit';
        console.log(currency1);

        let result = await user(item)

        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('username')) {
                    toast.info(result.errors.username[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('investment_amount')) {
                    toast.info(result.errors.investment_amount[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('currency1')) {
                    toast.info(result.errors.currency1[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.errors, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    useEffect(() => {

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
        getAllUsers();
    }, []);



    return (
        <><ToastContainer />
            <div className="container-fluid mt-5 mb-3">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="card mb-3">
                            <div className="card-header">
                                {(data[0]?.name)}
                                {/* {(data[0]?.name)} : ${parseFloat(data[0]?.minimum_price).toFixed(2) || '0.00'} - $ {parseFloat(data[0]?.maximum_price).toFixed(2) || '0.00'} */}
                            </div>
                            <div className="card-body">
                                <p className="card-text">ROI : {parseFloat(data[0]?.daily_return_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">Total Limit : {parseFloat(data[0]?.total_limit_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">ROI Limit : {parseFloat(data[0]?.roi_limit_percentage).toFixed(2) || '0.00'} %</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card mb-3">
                            <div className="card-header">
                                {(data[1]?.name)}
                                {/* {(data[1]?.name)} : ${parseFloat(data[1]?.minimum_price).toFixed(2) || '0.00'} - $ {parseFloat(data[1]?.maximum_price).toFixed(2) || '0.00'} */}
                            </div>
                            <div className="card-body">
                                <p className="card-text">ROI : {parseFloat(data[1]?.daily_return_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">Total Limit : {parseFloat(data[1]?.total_limit_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">ROI Limit : {parseFloat(data[1]?.roi_limit_percentage).toFixed(2) || '0.00'} %</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card ">
                            <div className="card-header">
                                {(data[2]?.name)}
                                {/* {(data[2]?.name)} : ${parseFloat(data[2]?.minimum_price).toFixed(2) || '0.00'} - $ {parseFloat(data[2]?.maximum_price).toFixed(2) || '0.00'} */}
                            </div>
                            <div className="card-body">
                                <p className="card-text">ROI : {parseFloat(data[2]?.daily_return_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">Total Limit : {parseFloat(data[2]?.total_limit_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">ROI Limit : {parseFloat(data[2]?.roi_limit_percentage).toFixed(2) || '0.00'} %</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-3 mb-3">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="card mb-3">
                            <div className="card-header">
                                {(data[3]?.name)}
                                {/* {(data[3]?.name)} : ${parseFloat(data[3]?.minimum_price).toFixed(2) || '0.00'} - $ {parseFloat(data[3]?.maximum_price).toFixed(2) || '0.00'} */}
                            </div>
                            <div className="card-body">
                                <p className="card-text">ROI : {parseFloat(data[3]?.daily_return_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">Total Limit : {parseFloat(data[3]?.total_limit_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">ROI Limit : {parseFloat(data[3]?.roi_limit_percentage).toFixed(2) || '0.00'} %</p>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="card">
                            <div className="card-header">
                                {(data[4]?.name)}
                                {/* {(data[4]?.name)} : ${parseFloat(data[4]?.minimum_price).toFixed(2) || '0.00'} - $ {parseFloat(data[4]?.maximum_price).toFixed(2) || '0.00'} */}
                            </div>
                            <div className="card-body">
                                <p className="card-text">ROI : {parseFloat(data[4]?.daily_return_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">Total Limit : {parseFloat(data[4]?.total_limit_percentage).toFixed(2) || '0.00'} %</p>
                                <p className="card-text">ROI Limit : {parseFloat(data[4]?.roi_limit_percentage).toFixed(2) || '0.00'} %</p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid mt-5 mb-5">
                <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="mm d-flex ml-0">Your Active Package Name : {(deposit?.active_package)}</div><hr />
                                <label for="exampleInputEmail1">Enter Username</label>

                                <input
                                    className="form-control mb-3"
                                    type="text"
                                    placeholder="Enter Username"
                                    onChange={(e) => setUsername(e.target.value)}
                                    name="username"
                                />
                                 {/* {all_data?.username === "admin" &&
                                            <div>
                                                
                                            </div>
                                        } */}      
                                <label for="exampleInputEmail1">Enter Amount :</label>
                                <input
                                    className="form-control mb-3"
                                    type="text"
                                    placeholder="Enter Amount In USD :"
                                    onChange={(e) => setInvestment_amount(e.target.value)}
                                    name="investment_amount"
                                />
                                <label for="exampleInputEmail1">Payment Method</label>
                                <select
                                    className="form-control mb-3"
                                    onChange={(e)=>setCurrency1("e.target.value")}
                                    name="currency1" >
                                    <option value="deposit" >Deposit Wallet (${parseFloat(deposit?.payment_method).toFixed(2) || '0.00'})</option>
                                </select>
                                <button type="button" onClick={sent} className="btn btn-dark  btn-block">Activate</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PurchasePackage;