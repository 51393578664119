import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import TwoTeam1 from "../Components/TwoTeam1";

const TwoTeam = () => {

    return (
        <>
            <UserAdmin
                card1={
                   <TwoTeam1 />
                }
             />
           
        </>
    )
}
export default TwoTeam;
