import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-use-history";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../css/register.css";
import { useLocation } from "react-router-dom"
const URL = "https://dev.arkmnetwork.com/api/user-register";
const URL2 = "https://dev.arkmnetwork.com/api/app/all_country";

// const href = window.location.href;
// // setNewUrl(href);
// console.log(href)

const Signup2 = () => {
    const [country, setcountry] = useState([]);
    const [user, setUser] = useState([]);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [repeatpasswordError, setRepeatpasswordError] = useState("");
    const [sponsorError, setSponsorError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [positionError, setPositionError] = useState("");
    const history = useHistory();

    const location = useLocation()
    const params = new URLSearchParams(location.search)


    const getUser = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get(`${URL2}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    const getAllUsers = async () => {
        let response = await getUser();
        setcountry(response.data.data)
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    const onValueChange = (e) => {
        console.log(e.target.name, e.target.value);
        setUser({ ...user, [e.target.name]: e.target.value });
        console.log(user);



        if (user.firstname.length == 0) {
            setFirstNameError("This field is require");
        } else
            if (user.firstname.length < 3) {
                setFirstNameError("min 3 char required");
            }else if (user.firstname.length >= 3){
                setFirstNameError("");
            }

        
        if (user.lastname.length == 0) {
            setLastNameError("This field is require");
        } else
            if (user.lastname.length < 3) {
                setLastNameError("min 3 char required");
            }else if (user.lastname.length >= 3){
                setLastNameError("");
            }

        if (user.email.length == 0) {
            setEmailError("This field is require");
        } else
            if (user.email.length < 4) {
                setEmailError("please enter a valid email");
            }else if (user.email.length >=4 ){
                setEmailError("");
        }

        if (user.username.length == 0) {
            setUsernameError("This field is require");
        } else
            if (user.username.length < 3) {
                setUsernameError("min 4 char required");
            }else if(user.username.length >= 4){
                setUsernameError("");
            }

        if (user.password.length === 0) {
            setPasswordError("This field is require");
        } else
            if (user.password.length < 4) {
                setPasswordError("please enter a strong password");
            }else if (user.password.length >= 4){
                setPasswordError("");
            }


        
        if (user.password_confirmation.length == 0) {
            setRepeatpasswordError("please enter your password again");
        } else
            if (!user.password_confirmation == user.password) {
                setRepeatpasswordError("password must match");
            }




        if (user.phone.length == 0) {
            setPhoneError("Please enter your phone number");
        } else
            if (!user.phone.length < 8) {
                setPhoneError("Please enter a valid number");
            }

    }

    const user1 = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,

                },

            })
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    const save = async () => {

        let result = await user1(user);

        if (!user.firstname || user.firstname === "") {
            setFirstNameError(result.errors.firstname[0]);
            return;
        }

        if (!user.lastname || user.lastname === "") {
            setLastNameError(result.errors.lastname[0])
            return;
        }

        if (!user.email || user.email === "") {
            setEmailError(result.errors.email[0])
            return;
        }
        if (!user.username || user.username === "") {
            setUsernameError(result.errors.username[0])
            return;
        }
        if (!user.password || user.password === "") {
            setPasswordError(result.errors.password[0])
            return;
        }
        if (!user.password_confirmation || user.password_confirmation === "") {
            setRepeatpasswordError(result.errors.password_confirmation[0])
            return;
        }
        if (!user.sponsor || user.sponsor === "") {
            setSponsorError(result.errors.sponsor[0])
            return;
        }
        if (!user.phone || user.phone === "") {
            setPhoneError(result.errors.phone[0])
            return;
        }
        if (!user.country || user.country === "") {
            setCountryError(result.errors.country[0])
            return;
        }
        if (!user.position || user.position === "") {
            setPositionError(result.errors.position[0])
            return;
        }

        if (result.hasOwnProperty('errors')) {
            if (result.errors.hasOwnProperty('firstname')) {
                toast.info(result.errors.firstname[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('lastname')) {
                toast.info(result.errors.lastname[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('email')) {
                toast.info(result.errors.email[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('username')) {
                toast.info(result.errors.username[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('password')) {
                toast.info(result.errors.password[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('repeatpassword')) {
                toast.info(result.errors.repeatpassword[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('sponsor')) {
                toast.info(result.errors.sponsor[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('mobile')) {
                toast.info(result.errors.mobile[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('country')) {
                toast.info(result.errors.country[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('position')) {
                toast.info(result.errors.position[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        } else {
            if (result.status == true) {
                setTimeout(() => {
                    history.push("/login")
                }, 3000);

                toast.info('Thank you! Your Registration has been successfully complete. This page automatically redirect to Login page!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        }
    }

    return (
        <>
            <div><ToastContainer />
                <div className="bg-gradient-dark">
                    <div className="container">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
                                    <div className="col-lg-7">
                                        <div className="reg">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                                            </div>

                                            <form className="user">
                                                <div className="form-group row">
                                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                                        <input type="text" onChange={(e) => onValueChange(e)} name="firstname" className="form-control form-control-user" placeholder="First Name" required />
                                                        {firstNameError && <small style={{ color: "red" }}>{firstNameError}</small>}
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input type="text" onChange={(e) => onValueChange(e)} name="lastname" className="form-control form-control-user" placeholder="Last Name" required />
                                                        {lastNameError && <small style={{ color: "red" }}>{lastNameError}</small>}
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input type="email" onChange={(e) => onValueChange(e)} name="email" className="form-control form-control-user" placeholder="Email Address" required />
                                                    {emailError && <small style={{ color: "red" }}>{emailError}</small>}
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" onChange={(e) => onValueChange(e)} name="username" className="form-control form-control-user" placeholder="Username" required />
                                                    {usernameError && <small style={{ color: "red" }}>{usernameError}</small>}
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                                        <input type="password" onChange={(e) => onValueChange(e)} name="password" className="form-control form-control-user" placeholder="Password" required />
                                                        {passwordError && <small style={{ color: "red" }}>{passwordError}</small>}
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input type="password" onChange={(e) => onValueChange(e)} name="password_confirmation" className="form-control form-control-user" placeholder="Repeat Password" required />
                                                        {repeatpasswordError && <small style={{ color: "red" }}>{repeatpasswordError}</small>}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                                        <input type="text" onChange={(e) => onValueChange(e)} value={(params.get("u")) } name="sponsor" className="form-control form-control-user" placeholder="Sponsor Code" />
                                                        {sponsorError && <small style={{ color: "red" }}>{sponsorError}</small>}
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <select className="select mob" onChange={(e) => onValueChange(e)} name="position" value={(params.get("leg")) || ""}>
                                                            <option className="form-control form-control-user">Select Position</option>
                                                            <option className="form-control form-control-user" value="right">Right</option>
                                                            <option className="form-control form-control-user" value="left">Left</option>
                                                        </select>
                                                        {positionError && <small style={{ color: "red" }}>{positionError}</small>}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                                        <select className="select mob" onChange={(e) => onValueChange(e)} name="country" required>
                                                            <option className="form-control form-control-user">Select Country</option>
                                                            {
                                                                country.map((country, index) => {
                                                                    return (
                                                                        <option className="form-control form-control-user" key={index} value={country.id}>{country.name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                        {countryError && <small style={{ color: "red" }}>{countryError}</small>}
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <input type="text" onChange={(e) => onValueChange(e)} name="phone" className="form-control form-control-user" placeholder="Mobile Number" required />
                                                        {phoneError && <small style={{ color: "red" }}>{phoneError}</small>}
                                                    </div>
                                                </div>

                                                <a onClick={save} className="btn btn-dark btn-user btn-block">
                                                    Register Account
                                                </a>
                                                <hr />
                                                <a href="/signup" className="btn btn-google btn-user btn-block">
                                                    <i className="fab fa-google fa-fw"></i> Register with Google
                                                </a>
                                                <a href="/signup" className="btn btn-facebook btn-user btn-block">
                                                    <i className="fab fa-facebook-f fa-fw"></i> Register with Facebook
                                                </a>
                                            </form>
                                            <hr />
                                            
                                            <div className="text-center">
                                                <a className="small" href="/login">Already have an account? Login!</a>
                                                
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Signup2;