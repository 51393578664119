import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-use-history";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import moment from 'moment';

const URL = "https://dev.arkmnetwork.com/api/user-login";

const user = async (data) => {
    try {
        var result = await axios.post(`${URL}`, data )
        return result.data;
    } catch (error) {
        console.log(error)
    }
}

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggedin, setisLoggedin] = useState("");
    const history = useHistory()
    const [access_token, setaccess_token] = useState('');

    useEffect(() => {
        let isLoggedin = localStorage.getItem('isLoggedin');
        setisLoggedin(isLoggedin)
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

        if (isLoggedin == true) {
            history.push("/");
        }

        // if (isLoggedin == false) {
        //     history.push("/login");
        // }

    }, [isLoggedin])

    async function doLogin() {
        console.warn(email, password)
        let item = { username: email, password };
        console.log(item);
        // loading on
        let result = await user(item)

        console.log(result);
        if (result.status == true) {
            localStorage.setItem('isLoggedin', true);
            localStorage.setItem('access_token', result.access_token);
            history.push("/");
        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('username')) {
                    toast.info(result.errors.username[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
                if (result.errors.hasOwnProperty('password')) {
                    toast.info(result.errors.password[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
    }

    return (
        <>
        <ToastContainer />
            <div className="bg-gradient-dark">
                <div className="container">
                    {/* <!-- Outer Row --> */}
                    <div className="row justify-content-center">

                        <div className="col-xl-10 col-lg-12 col-md-9">

                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    {/* <!-- Nested Row within Card Body --> */}
                                    <div className="row">
                                        <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                                </div>
                                                <form className="user">
                                                    <div className="form-group">
                                                        <input
                                                        type="email"
                                                        className="form-control form-control-user"
                                                        id="exampleInputEmail"
                                                        aria-describedby="emailHelp"
                                                        value={email}
                                                        placeholder="Enter Email Address / Username" 
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" className="form-control form-control-user"
                                                            id="exampleInputPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox small">
                                                            <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                            <label className="custom-control-label" for="customCheck">Remember
                                                                Me</label>
                                                        </div>
                                                    </div>
                                                    <a onClick={doLogin} className="btn btn-dark btn-user btn-block">
                                                        Login
                                                    </a>
                                                    <hr />

                                                    <a href="index.html" className="btn btn-google btn-user btn-block">
                                                        <i className="fab fa-google fa-fw"></i> Login with Google
                                                    </a>
                                                    <a href="index.html" className="btn btn-facebook btn-user btn-block">
                                                        <i className="fab fa-facebook-f fa-fw"></i> Login with Facebook
                                                    </a>
                                                </form>
                                                <hr />
                                                <div className="text-center">
                                                    <a className="small text-dark" href="/reset-password">Forgot Password?</a>
                                                </div>
                                                <div className="text-center">
                                                    <a className="small text-dark" href="/signup">Create an Account!</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;