import React from "react";

const Memberaccountstatement = () => {
    return (
        <>
            <div className="card ml-4 mr-4 mb-5 mt-5">
                <div className="card-header">Member Account Statement</div>
                <form className="ml-5 mr-5 mt-4 mb-5">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label for="inputEmail4">Username</label>
                            <input type="text" className="form-control" id="inputEmail4" placeholder="Username" />
                        </div>
                        <div className="form-group col-md-6">
                            <label for="inputEmail4">Type</label>
                            <select id="inputState" className="form-control">
                                <option selected>All</option>
                                <option>Credit</option>
                                <option>Debit</option>
                            </select>
                        </div>
                    </div>       
                    <button type="button" class="btn btn-dark btn-block">Search</button>

                </form>
            </div>

        </>
    )
}
export default Memberaccountstatement;
