import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';

const URL = "https://dev.royaltrading.world/api/app/user-team-details";

const LevelDetails = () => {
    const [item, setItem] = useState();
    // const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');
    // const [downlineDetails, setdownlineDetails] = useState();
    // const [column, setcolumn] = useState(["Level","User_Count","Investment"]);
    const [data, setData] = useState([]);

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async () => {
        let response = await getItem();
        setItem(response.data.downlineDetails);
        // setFilteredItem(response.downlineDetails);
        // setdownlineDetails(response.downlineDetails)
        // console.log(item)

        for (let i = 1; i < item.length; i++) {
            let totalInvestment = 0
            item.downlineDetails[i].map(item => {
                totalInvestment += item.investment
            })
    
            const Data = {
                "id": i,
                "Level": `Level${i}`,
                "User_Count": item.downlineDetails[i].length,
                "Investment": totalInvestment
            }
            setData(...data , Data)
        }
    }

    const columns = [
        {
            name: "Level",
            selector: row => row.data.Level,
            sortable: true,
        },
        {
            name: "User_Count",
            selector: row => row.data.User_Count,
            sortable: true,
        },
        {
            name: "Investment",
            selector: row => row.data.Investment,
            sortable: true,
        }
    ]

    useEffect(() => {
        getdata();
        console.log(item)
        console.log(item.length)
        console.log(data)
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    return (
        <>
            <UserAdmin
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                        <DataTable
                            title={<h5 className="mt-4">Level Details</h5>}
                            columns={columns}
                            data={data}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            selectableRow
                            selectableRowsHighlight
                            highlightOnHover
                            actions={
                                <button className="btn btn-md btn-dark mt-3">Export</button>
                            }
                        />
                    </div>
                }
            />

        </>
    )
}
export default LevelDetails;