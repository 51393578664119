import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import {CSVLink} from "react-csv";
import moment from 'moment';

const URL = "https://dev.arkmnetwork.com/api/app/network/referred-members";

const DirectReferrals = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.users.data);
        setFilteredItem(response.data.users.data);
        console.log(item)
    }

    const columns = [
        {
            name: <h6>Username</h6>,                   
            selector: row => <div className="text-warning">{(row.username)}</div>,               
            sortable: true,
        },
        {
            name: <h6>Member Name</h6>,
            selector: row => <div className="text-success">{(row.first_name+" "+row.last_name)}</div>,                
            sortable: true,
        },
        {
            name: <h6>Position</h6>,
            selector: row => row.position,                
            sortable: true,
        },
        {
            name:<h6>Active Package</h6>,
            selector: row => row.package_name,            
            sortable: true,
            
        },
        {
            name:<h6>Joined On</h6>,
            selector: row => row.created_at,           
            sortable: true,
            cell: (row) => (
                <div>
                    {moment(row.created_at).format("MMMM Do YYYY")}
                </div>
            ),
        },

    ]

    useEffect(() => {

        getdata();

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    return (
        <>
            <UserAdmin
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                    <DataTable
                        title={<h5 className="mt-4">Direct Referrals</h5>}
                        columns={columns}
                        data={item}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="600px"
                        selectableRow
                        selectableRowsHighlight
                        highlightOnHover
                        // actions={<CSVLink {...csvLink} className="btn btn-md btn-dark mt-3">Export</CSVLink>}
                        actions={
                            <CSVLink data={item} filename="Direct_Referrals.csv">
                                <button className="btn btn-md btn-dark mt-3">Export</button>
                            </CSVLink>
                        }
                    />
                    </div>
                } />
           
        </>
    )
}
export default DirectReferrals;
