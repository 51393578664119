import React from "react";
import Signup2 from "../Components/Signup2";
// import Signup1 from "../Components/Signup1";
// import SignupNew from "../Components/SignupNew";
// import Signup3 from "../Components/Signup3";
const Register=()=>{
    return(
        <>
            <div className="container-fluid bg-dark">
                <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">
                        <Signup2/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;