import React from "react";
import Dashboard1 from "../Components/Dashboard/Dashboard1";

const Dashboard=()=>{
    return(
        <>
        <Dashboard1/>
       
        {/* <UserAdmin
            card1={<UserInfo/>}
            card2={<UserDetails/>}
        /> */}

        {/* <UserAdmin
            card1={<UserInfo/>}
            card2={<UserDetails2/>}
        /> */}
        
        </>
    )
}
export default Dashboard;