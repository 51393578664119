import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-use-history";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "../css/password.css";
const URL = "https://dev.arkmnetwork.com/api/forget";  

const user1 = async (data) => {
    let access_token = localStorage.getItem('access_token');
    try {
        var result = await axios.post(`${URL}`, data, {
            headers: {
                "Authorization": "Bearer " + access_token,
            },
        })
        return result.data;
    } catch (error) {
        console.log(error)
    }
}

const ForgotPassword = () => {
    const [user, setUser] = useState([]);
    const history = useHistory();
    const [usernameError, setusernameError] = useState("");

    const onValueChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });

        if (user.username.length == 0) {
            setusernameError("This field is require");
        } else
            if (user.username.length <= 3) {
                setusernameError("please enter a valid username");
            }else if(user.username.length > 3){
                setusernameError("");
            }
    }

    const reset = async () => {
        let result = await user1(user);

        if (result.hasOwnProperty('errors')) {
            if (result.errors.hasOwnProperty('username')) {
                toast.info(result.errors.username[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        } else {
            if (result.status == true) {
                // setTimeout(() => {
                //     history.push("/login")
                // }, 5000);

                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        }
    }

    return (
        <>  
            <div className="container bg-dark">
                <div className="card o-hidden border-0 shadow-lg boxx">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                        <div className="col-lg-6">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-2 ">Forgot Your Password?</h1>
                                    <h6 className="mb-4">We get it, stuff happens. Just enter your email address below
                                        and we'll send you a link to reset your password!</h6>
                                </div>
                                <form className="user">
                                    <div className="form-group">
                                        <input name="username" type="text" className="form-control form-control-user"
                                            onChange={(e) => onValueChange(e)} id="exampleInputEmail" aria-describedby="emailHelp"
                                            placeholder="Enter Your Username..." required />
                                        {usernameError && <small style={{ color: "red" }}>{usernameError}</small>}
                                    </div>
                                    <button type="button" onClick={reset} className="btn btn-dark btn-user btn-block">
                                        Reset Password
                                    </button>
                                </form>

                                <hr />
                                <div className="text-center text-dark">
                                    <a className="small text-dark" href="/signup">Create an Account!</a>
                                </div>
                                <div className="text-center text-dark">
                                    <a className="small text-dark" href="/login">Already have an account? Login!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};
export default ForgotPassword;
