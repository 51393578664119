import React from "react";
import UserAdmin from "../../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState,useEffect } from "react";
import axios from 'axios';
import Managesubscription from "../admincomponents/Managesubscription";
const URL = "https://dev.royaltrading.world/api/app/subscription";

const MemberSubscription = () => {
    const [item, setItem] = useState();
    const [filteredItem, setFilteredItem] = useState();
    const [access_token, setaccess_token] = useState();

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }
    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.subcriptions.data);
        setFilteredItem(response.data.subcriptions.data);
        console.log(item)

    }
    const columns = [
        {
            name: <h6>Activation Date</h6>,                  
            selector: row =>row.created_at,             
            sortable: true,
          
        },
        {
            name: <h6>User Id</h6>,
            selector: row => row.user_name,               
            sortable: true,
        },
        {
            name: <h6>Subscription done By</h6>,
            selector: row =>row.subscriptionDoneBy,               
            sortable: true,
        },
        {
            name: <h6>Price</h6>,
            selector: row => "$  "+row.price,            
            sortable: true,
        },
        {
            name: <h6>Total Income Limit</h6>,
            selector: row => "$  "+row.total_income_limit,           
            sortable: true,
        },
        {
            name: <h6>Paid Income Limit</h6>,
            selector: row => "$  "+row.total_paid_income,            
            sortable: true,
        },
        {
            name: <h6>Total ROI Income Limit</h6>,
            selector: row => "$  "+row.total_roi_income_limit,            
            sortable: true,
        },
        {
            name: <h6>Paid ROI Income Limit</h6>,
            selector: row => "$  "+row.paid_roi_income,            
            sortable: true,
        },
        {
            name: <h6>Status</h6>,
            selector: row =>(<div> {row.status == 1 ? (<span >Active</span>) : (<span>Inactive</span>)} </div>),           
            sortable: true,
        },
        {
            name: <h6>Roi Status</h6>,
            selector: row =>(<div> {row.roi_status == 1 ? (<span >Active</span>) : (<span>Inactive</span>)} </div>),         
            sortable: true,
        },
        {
            name: <h6>Action</h6>,
            selector: row => (<div><i></i>Click Here to Inactive ROI</div>),          
            sortable: true,
        }
    ]
    
    useEffect(() => {

        getdata();

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    return (
        <>
            <UserAdmin
            card1={
                <Managesubscription/>
            }
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                    <DataTable
                        title={<h5 className="mt-4">Subscription List</h5>}
                        columns={columns}
                        data={filteredItem}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="600px"
                        selectableRow
                        selectableRowsHighlight
                        highlightOnHover
                        actions={
                            <button className="btn btn-md btn-dark mt-3">Export</button>
                        }
                    />
                    </div>
                } />
           
        </>
    )
}
export default MemberSubscription;