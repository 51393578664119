import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-use-history";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "../css/password.css";
const URL = "https://dev.arkmnetwork.com/api/password/reset";

const user1 = async (data) => {
    let access_token = localStorage.getItem('access_token');
    try {
        var result = await axios.post(`${URL}`, data, {
            headers: {
                "Authorization": "Bearer " + access_token,
            },
           
        })
        return result.data;
    } catch (error) {
        console.log(error)
    }
}

const ResetMailPassword = () => {
    const [user, setUser] = useState([]);
    const history = useHistory();
    const [usernameError, setusernameError] = useState("");
    const [passwordError, setpasswordError] = useState("");
    const [password_confirmationError, setpassword_confirmationError] = useState("");

    const onValueChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });

        if(user.username.length == 0){
            setusernameError("This field is require");
        }else
        if(user.username.length >= 1 ){
            setusernameError("please enter a valid username");
        }

        if(user.password.length == 0){
            setpasswordError("Please enter your new password");
        }else
        if(user.password.length >= 1 ){
            setpasswordError("Please enter a strong password");
        }

        if(user.password_confirmation.length == 0){
            setpassword_confirmationError("Please confirm your password");
        }else
        if(!user.password_confirmation == user.password){
            setpassword_confirmationError("Password must match");
        }
    }

    const reset = async () => {
        let result = await user1(user);

        if (result.hasOwnProperty('errors')) {
            if (result.errors.hasOwnProperty('username')) {
                toast.info(result.errors.username[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('password')) {
                toast.info(result.errors.password[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            if (result.errors.hasOwnProperty('password_confirmation')) {
                toast.info(result.errors.password_confirmation[0], {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        } else {
            if (result.status == true) {
                setTimeout(() => {
                    history.push("/login")
                }, 5000);

                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        }
    }
    return (
        <>
            <body className="bg-gradient-primary" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-12 col-md-9">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                                                    <p className="mb-4">We get it, stuff happens. Just enter your email address below
                                                        and we'll send you a link to reset your password!</p>
                                                </div>
                                                <form className="user">
                                                    <div className="form-group">
                                                        <input name="username" type="text" className="form-control form-control-user mb-2"
                                                            onChange={(e) => onValueChange(e)} placeholder="Enter Your Username..." required />
                                                            {usernameError && <small style={{ color: "red" }} className="mb-2">{usernameError}</small>}

                                                            <input name="password" type="password" className="form-control form-control-user mb-2"
                                                            onChange={(e) => onValueChange(e)}  placeholder="New Password" required />
                                                            {passwordError && <small style={{ color: "red" }} className="mb-2">{passwordError}</small>}

                                                            <input name="password_confirmation" type="password" className="form-control form-control-user mb-2"
                                                            onChange={(e) => onValueChange(e)} placeholder="Confirm New Password" required />
                                                            {password_confirmationError && <small style={{ color: "red" }} className="mb-2">{password_confirmationError}</small>}
                                                    </div>
                                                    <button type="button" onClick={reset} className="btn btn-dark btn-user btn-block">
                                                        Reset Password
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><ToastContainer />
                </div>
            </body>
        </>
    )
}
export default ResetMailPassword;
