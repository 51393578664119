import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import axios from 'axios';
import WithdrawBalance from "../Components/WithdrawBalance";
import { CSVLink } from "react-csv";

const URL = "https://dev.arkmnetwork.com/api/app/wallet/withdraw";

const Withdraw = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');
    const [withdraw, setWithdraw] = useState([]);

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.withdraws.data);
        setFilteredItem(response.data.withdraws.data);
        setWithdraw(response.data)
    }

    const columns = [
        {
            name: <h6>Date</h6>,
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: <h6>Amount</h6>,
            selector: row => <div className="text-warning"><b>USD  </b><b>{parseFloat(row.net_amount).toFixed(0)}</b></div>,
            sortable: true,
        },
        {
            name: <h6>Receivable Amount</h6>,
            selector: row => <div className="text-info"><b>USD  </b><b>{parseFloat(row.amount_from).toFixed(0)}</b></div>,
            sortable: true,
        },
        {
            name: <h6>Method</h6>,
            selector: row => row.coin_type,
            sortable: true,
        },
        {
            name: <h6>Token Price</h6>,
            selector: row =><div className="text-success"><b>USD  </b><b>{parseFloat(row.token_price).toFixed(2)}</b></div>,
            sortable: true,
        },
        {
            name: <h6>Amount Receivable</h6>,
            selector: row => <div className="text-success"><b>{parseFloat(row.amount_to).toFixed(2)}</b><b>  ARKM</b></div>,
            sortable: true,
        },
        {
            name: <h6>Account</h6>,
            selector: row => <div><b className="text-xs" >Account:</b><br></br><span>{(row.address)}</span></div>,
            sortable: true,
        },
        {
            name: <h6>Tx ID</h6>,
            selector: row => row.code,
            sortable: true,
        },
        {
            name: <h6>Status</h6>,
            selector: row => row.status + "  At- " + row.updated_at,
            sortable: true,
        },
        // {
        //     name: "Action",
        //     cell: (row) => <button className="btn btn-dark" onClick={() => alert(row.id)}>Edit</button>
        // },
    ]

    useEffect(() => {
        getdata();
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    return (
        <>
            <UserAdmin
                card1={
                    <WithdrawBalance
                        bal={parseFloat(withdraw?.withdraw_balance).toFixed(2) || '0.00'}
                        add={(withdraw?.withdrawal_address)}
                        token={parseFloat(withdraw?.token_balance).toFixed(2) || '0.00'}
                        currentp={parseFloat(withdraw?.tokenPrice).toFixed(2) || '0.00'}
                        gamingwallet={parseFloat(withdraw?.gaming_balance).toFixed(2) || '0.00'}

                    />
                }
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                        <DataTable
                            title={<h5 className="mt-4">Withdraw</h5>}
                            columns={columns}
                            data={item}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            selectableRow
                            selectableRowsHighlight
                            highlightOnHover
                            actions={
                                <CSVLink data={item} filename="Withdraw.csv">
                                    <button className="btn btn-md btn-dark mt-3">Export</button>
                                </CSVLink>
                            }
                        />
                    </div>
                }

            />

        </>
    )
}
export default Withdraw;