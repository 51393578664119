import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import "../css/referral.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GiTakeMyMoney } from 'react-icons/gi';
import { BsFillGiftFill } from 'react-icons/bs';

const URL = "https://dev.arkmnetwork.com/api/app/profile/send-otp";
const URL2 = "https://dev.arkmnetwork.com/api/app/wallet/withdraw/cash";
const URL3 = "https://dev.arkmnetwork.com/api/app/game-wallet-withdraw";
const URL4 = "https://dev.arkmnetwork.com/api/app/dashboard";

const WithdrawBalance = (props) => {
    const [camount, setCamount] = useState();
    const [email_otp, setEmail_otp] = useState();
    const [cwallet, setCwallet] = useState("withdraw");
    const [users, setUsers] = useState({});
    const [email, setEmail] = useState([]);

    const [access_token, setaccess_token] = useState();
    const [item, setItem] = useState();
    const [item2, setItem2] = useState();
    const [item3, setItem3] = useState();
    const [withdraw, setWithdraw] = useState();
    const [all_data, setAllData] = useState({});
    const [loading, setLoading] = useState(false)

    const getUser = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get(`${URL4}`,
                {
                    headers: {
                        // "Content-Type": "application/json",
                        // "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    const getAllUsers = async () => {
        let response = await getUser();
        setUsers(response.data.data);
        setAllData(response.data.user);
        setEmail(response.data.user)
        console.log(email)
    }

    const user = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get(`${URL}`,
                {
                    headers: {
                        // "Content-Type": "application/json",
                        // "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result.data;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    async function sent() {
        let item = { cwallet, camount };
        console.log("item");
        // if(!item.length === 0){
        let result = await user(item)
        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const postData = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL2}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                    // "Accept": "*/*",
                    // 'Access-Control-Allow-Methods': 'OPTIONS,POST',
                    // 'Access-Control-Allow-Credentials': true,
                    // 'Access-Control-Allow-Origin': '*',
                    // 'X-Requested-With': '*',
                    // "Cache-Control": "no-cache",
                    // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                // "async": true,
                // "crossDomain": true,
                // "method": "POST",
            })
            // console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function post() {
        let item2 = { cwallet, camount, email_otp };
        console.log(item2);
        let result = await postData(item2)

        console.log(result)
        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('cwallet')) {
                    toast.info(result.errors.cwallet[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('camount')) {
                    toast.info(result.errors.camount[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                if (result.errors.hasOwnProperty('email_otp')) {
                    toast.info(result.errors.email_otp[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // console.log(result)
            }
        }
    }

    useEffect(() => {
        getAllUsers();
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 5000);

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    const user2 = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get(`${URL}`,
                {
                    headers: {
                        // "Content-Type": "application/json",
                        // "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result.data;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    async function sent2() {
        let item3 = { cwallet, camount };
        console.log(item3);
        // if(!item.length === 0){
        let result = await user2(item3)
        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const postData2 = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL3}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                    // "Accept": "*/*",
                    // 'Access-Control-Allow-Methods': 'OPTIONS,POST',
                    // 'Access-Control-Allow-Credentials': true,
                    // 'Access-Control-Allow-Origin': '*',
                    // 'X-Requested-With': '*',
                    // "Cache-Control": "no-cache",
                    // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                // "async": true,
                // "crossDomain": true,
                // "method": "POST",
            })
            // console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function sent3() {
        let item4 = { cwallet, camount, email_otp };
        console.log(item4);
        // if(!item.length === 0){
        let result = await postData2(item4)
        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        if (result.errors.hasOwnProperty('camount')) {
            toast.info(result.errors.camount[0], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        if (result.errors.hasOwnProperty('email_otp')) {
            toast.info(result.errors.email_otp[0], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        <><ToastContainer />
            <div className="col-12 d-flex">
                <div className="col-xl-4 col-md-6 mb-4 ">
                    <div className="card border-left-primary shadow h-100 py-2 ">
                        <div className="card-body box2s">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2 mb-1">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-2">
                                        WITHDRAW WALLET</div>
                                    {/* <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">WITHDRAW WALLET</div> */}
                                    <div className="h5 font-weight-bold text-gray-800">${props.bal}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-wallet fa-2x gray"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="card border-left-secondary shadow h-100 py-2 box2s">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2 mb-1">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-2">
                                        TOKEN WALLET</div>
                                    {/* <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">TOKEN WALLET</div> */}
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${props.token}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas  fa-2x gray"><GiTakeMyMoney /></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2 box2s">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2 mb-1">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-2">
                                        CURRENT PRICE</div>
                                    {/* <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">CURRENT PRICE</div> */}
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${props.currentp}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-2x gray"><BsFillGiftFill /></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container-fluid mt-3 mb-3 ">

                <div className="row ">
                    <div className="col-12 col-md-6  ">
                        <div className="card ">
                            <div className="card-header bg-dark text-light">
                                Your Account Address is set to {props.add} .
                            </div>
                            <div className="card-body">
                                <form className="mt-1">
                                    <div>
                                        <div className="col">
                                            Wallet:
                                            <select className="form-control mb-2"
                                                onChange={(e) => setCwallet(e.target.value)}
                                                name="cwallet">
                                                <option value="withdraw">Withdraw wallet</option>
                                                <option value="token">Token Wallet</option>
                                            </select>
                                        </div>

                                        <div className="col">
                                            Amount ($) :
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                placeholder="Amount :"
                                                onChange={(e) => setCamount(e.target.value)}
                                                name="camount"
                                            />
                                        </div>
                                        <div className="col">
                                            One Time Password (OTP):
                                            <input
                                                type="text"
                                                name="email_otp"
                                                className="form-control mb-2"
                                                onChange={(e) => setEmail_otp(e.target.value)}
                                            />
                                        </div>

                                        <div className="col">
                                            <button
                                                type="button"
                                                class="btn btn-dark  btn-block mb-2"
                                                onClick={sent}>Get OTP on email</button>
                                        </div>
                                        <div className="text-xs  ml-3">
                                            <p>Liquidity Amount :<span className="float-right mr-3">$ ...</span></p>
                                            <p>Gaming Amount :<span className="float-right mr-3">$ ...</span></p>
                                            <p>Withdraw Net Amount : <span className="float-right mr-3">$ ...</span></p>
                                            <p>Withdraw Amount : <span className="float-right mr-3">TOKEN ...</span></p>
                                        </div>

                                        <div className="col">
                                            <button onClick={post} type="button" class="btn btn-dark btn-block">Proceed</button>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-header bg-dark text-light">
                                Withdrawal Rules
                            </div>
                            <div className="card-body">
                                <p className="h6">Minimum withdraw amount is $10<br></br><br></br>
                                    Withdrawable amount will be credited in Account Address : {props.add}<br></br><br></br>
                                    All withdrawals will be processed within 24 hours.<br></br><br></br></p>
                                <p ><b>Liquidity Pool Amount 15% <br></br></b> </p>
                                <p>15% of amount will be deducted from every Withdraw</p>
                                <p ><b>Gaming Amount 5%<br></br></b> </p>
                                <p>5% of amount will be deducted from every Withdraw</p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="row ">
                    <div className="col-12 col-md-12 mt-5">
                        <div className="card ">
                            <div className="card-header bg-dark text-light">
                                Gaming Wallet Withdraw : ${props.gamingwallet}
                                {/* {props.bal} */}
                            </div>
                            <div className="card-body">
                                {/* <label className="btn btn-dark  btn-block">Your Current Withdraw Method is BNB and Account Address is set to {props.add}.</label> */}
                                <label className="btn btn-dark  btn-block">*Please create your account at https://arkeriagames.com using <b>{(email?.email)}</b> before making withdraw else you may lose your balance.</label>

                                <form className="mt-4">
                                    <div>
                                        <div className="col">
                                            Wallet:
                                            <select className="form-control mb-2"
                                                onChange={(e) => setCwallet(e.target.value)}
                                                name="cwallet">
                                                <option value="gaming" selected>Gaming</option>
                                            </select>
                                        </div>
                                        <div className="col">
                                            Amount ($) :
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                placeholder="Wallet:"
                                                onChange={(e) => setCamount(e.target.value)}
                                                name="camount"

                                            />
                                        </div>
                                        <div className="col">
                                            One Time Password (OTP):
                                            <input
                                                type="text"
                                                name="email_otp"
                                                className="form-control mb-2"
                                                onChange={(e) => setEmail_otp(e.target.value)}
                                            />
                                        </div>

                                        <div className="col">
                                            <button
                                                type="button"
                                                class="btn btn-dark  btn-block mb-2"
                                                onClick={sent2}>Get OTP on email</button>
                                        </div>

                                        <div className="col">
                                            <button onClick={sent3} type="button" class="btn btn-dark btn-block">Proceed</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WithdrawBalance;