import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const URL = "https://dev.royaltrading.world/api/app/member";


const Managemember = () => {
    const [keyword, setKeyword] = useState();
    const [access_token, setaccess_token] = useState();
    const [username, setUsername] = useState();
    const [package_id, setPackage_id] = useState();
    const [country_id, setCountry_id] = useState();
    const [from_date, setFrom_date] = useState();
    const [to_date, setTo_date] = useState();
    const [from_act_date, setFrom_act_date] = useState();
    const [to_act_date, setTo_act_date] = useState();
    const [subscription, setSubscription] = useState();
    const [status, setStatus] = useState();



    const postData = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                    "Accept": "*/*",
                    'Access-Control-Allow-Methods': 'OPTIONS,POST',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Origin': '*',
                    'X-Requested-With': '*',
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                "async": true,
                "crossDomain": true,
                "method": "GET",
            })
            // console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function post() {
        let item2 = { username, package_id, from_date,to_date,from_act_date,to_act_date,subscription,status };
        console.log(item2);
        let result = await postData(item2)

        console.log(result)
        if (result.status == true) {
             toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('cwallet')) {
                     toast.info(result.errors.cwallet[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
                if (result.errors.hasOwnProperty('camount')) {
                     toast.info(result.errors.camount[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
                if (result.errors.hasOwnProperty('email_otp')) {
                     toast.info(result.errors.email_otp[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            } else {
                 toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                // console.log(result)
            }
        }
    }

    useEffect(() => {

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    return (
        <>  
        <ToastContainer />
        <div className="card ml-4 mr-4 mb-5 mt-5">
            <div className="card-header">Manage Member</div>
            <form className="ml-5 mr-5 mt-4 mb-5">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Keyword</label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            onChange={(e) => setKeyword(e.target.value)}
                            name="keyword"
                            placeholder="Keyword" />
                    </div>
                    <div className="form-group col-md-6">
                        <label for="inputPassword4">Username</label>
                        <input
                            type="text"
                            onChange={(e) => setUsername(e.target.value)}
                            name="username"
                            className="form-control"
                            id="inputEmail4"
                            placeholder="Username" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Package</label>
                        <select id="inputState" className="form-control">
                            onChange={(e) => setPackage_id(e.target.value)}
                            name="package_id"
                            <option selected value="">All</option>
                            <option value="60d706d4196fe">PACKAGE 1</option>
                            <option value="63888f69aa544">PACKAGE 2</option>
                            <option value="63888f69ah544">PACKAGE 3</option>
                            <option value="63888k69ah544">PACKAGE 4</option>
                            <option value="63878k69ah544">PACKAGE 5</option>
                        </select>
                    </div>
                    <div className="form-group col-md-6">
                        <label for="inputPassword4">Country</label>
                        <select id="inputState" className="form-control">
                            onChange={(e) => setCountry_id(e.target.value)}
                            name="country_id"
                            <option selected value="">All</option>
                            <option>...</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Joined Between</label>
                        <div className="d-flex">
                            <input
                                type="date"
                                className="form-control"
                                id="inputEmail4"
                                onChange={(e) => setFrom_date(e.target.value)}
                                name="from_date"
                                placeholder="Keyword" />

                            <input
                                type="date"
                                className="form-control"
                                onChange={(e) => setTo_date(e.target.value)}
                                name="to_date"
                                id="inputEmail4"
                                placeholder="Keyword" />
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label for="inputPassword4">Subscribe Between</label>
                        <div className="d-flex">

                            <input
                                type="date"
                                className="form-control"
                                onChange={(e) => setFrom_act_date(e.target.value)}
                                name="from_act_date"
                                id="inputEmail4"
                                placeholder="Keyword" />

                            <input
                                type="date"
                                className="form-control"
                                onChange={(e) => setTo_act_date(e.target.value)}
                                name="to_act_date"
                                id="inputEmail4"
                                placeholder="Keyword" />

                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Subscription</label>
                        <select
                            id="inputState"
                            className="form-control"
                            onChange={(e) => setSubscription(e.target.value)}
                            name="subscription"
                        >

                            <option selected value="">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                            <option>...</option>
                        </select>
                    </div>
                    <div className="form-group col-md-6">
                        <label for="inputPassword4">Status</label>
                        <select
                            id="inputState"
                            className="form-control"
                            onChange={(e) => setStatus(e.target.value)}
                            name="status"
                        >

                            <option selected value="">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>
                </div>
                <button type="submit" name="search" onClick={post} className="btn btn-dark">Search</button>
            </form>
        </div>
        </>
    )
}
export default Managemember;
