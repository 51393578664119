import React from "react";

const Managepackage = () => {
    return (
        <>
        <div className="card ml-4 mr-4">
            <div className="col-12 mt-4 mb-4">
            <button type="button" class="btn btn-dark float-right">Large button</button>
             </div>
        </div>
        <div className="card ml-4 mr-4 mb-5 mt-5">
            <div className="card-header">Packages</div>
            <form className="ml-5 mr-5 mt-4 mb-5">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Name</label>
                        <input type="text" className="form-control" id="inputEmail4" placeholder="Enter Package Name" />
                    </div>
                    <div className="form-group col-md-6">
                        <label for="inputPassword4">Minimum price ($)</label>
                        <input type="text" className="form-control" id="inputEmail4" placeholder="Enter Minimum price" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Maximum price ($)</label>
                        <input type="text" className="form-control" id="inputEmail4" placeholder="Enter Maximum price" />
                    </div>
                    <div className="form-group col-md-6">
                        <label for="inputPassword4">Daily Return Percentage (%)</label>
                        <input type="text" className="form-control" id="inputEmail4" placeholder="Enter Daily Return Percentage" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Total income Percentage (%)</label>
                        <input type="text" className="form-control" id="inputEmail4" placeholder="Enter total income Percentage" />
                    </div>
                    <div className="form-group col-md-6">
                        <label for="inputPassword4">Non working Percentage (%)</label>
                        <input type="text" className="form-control" id="inputEmail4" placeholder="Enter non working Percentage" />
                    </div>
                </div>
               
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Status</label>
                        <select id="inputState" className="form-control">
                            <option selected>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div>
                    <div className="form-group col-md-6 mt-4">
                        <button type="button" class="btn btn-dark mt-2 ml-3 col-5">Submit</button>
                        <button type="button" class="btn btn-secondary mt-2 ml-3 col-5">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
        </>
    )
}
export default Managepackage;
