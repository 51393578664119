import React from "react";
import { FaUserAlt } from 'react-icons/fa';
import { AiOutlineBarChart } from 'react-icons/ai';
import { BiNetworkChart } from 'react-icons/bi';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { FaNetworkWired } from 'react-icons/fa';

import "../../vendor/fontawesome-free/css/all.min.css";
import "../../css/sb-admin-2.min.css";
import "../../css/my.css";
import "../../vendor/fontawesome-free/css/all.min.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';


const mystyle = {
    margin: "auto",
    verticalAlign: "middle",
    marginTop: "280px",
    marginLeft:"50%"
};

function disable() {
    if (document.getElementById('accordionSidebar').style.display === 'block') {
        document.getElementById('accordionSidebar').style.display = 'none'
    } else {
        document.getElementById('accordionSidebar').style.display = 'block'
    }
}

const UserAdmin = (props) => {
    const [loading, setLoading] = useState(false)
    const [access_token, setaccess_token] = useState('');
    const [all_data, setAllData] = useState({});

    const getUser = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get("https://dev.arkmnetwork.com/api/app/dashboard",
                {
                    headers: {
                        // "Content-Type": "application/json",
                        // "Accept": "application/json",
                        "Authorization": "Bearer " + access_token

                    }
                })
            return result;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    const getAllUsers = async () => {
        let response = await getUser();
        setAllData(response.data.user);
    }

    useEffect(() => {
        getAllUsers();
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 5000);

    }, []);

    return (
        <>
            <div>
                {
                    loading ?
                        (<ScaleLoader
                            color={'gray'}
                            loading={loading}
                            cssOverride={mystyle}
                            size={100}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />) : (
                            <div id="wrapper">
                            <ul className="navbar-nav bg-dark sidebar sidebar-dark accordion" id="accordionSidebar">
                                    <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                                        <div className="sidebar-brand text mx3">
                                            <img src="logo.png" alt="logo" className="mt-0.5 logo"></img>
                                        </div>
                                        <div className="sidebar-brand-icon">
                                            <img src="logo.png" alt="logo" className="mt-1 fas logo1"></img>
                                        </div>
                                    </a>
                                    <hr className="sidebar-divider my-0" />
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/">
                                            <i className="fa fa-home"></i>
                                            <span>Dashboard</span></a>
                                    </li>
                                    <hr className="sidebar-divider" />
                                    <div className="sidebar-heading">
                                        Account
                                    </div>
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseTwo"
                                            aria-expanded="true" aria-controls="collapseTwo">
                                            <i className="h3" aria-hidden="true"><AiOutlineBarChart /></i>
                                            <span>Finance</span>
                                        </a>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                            <div className="bg-white py-2 collapse-inner rounded">
                                                <h6 className="collapse-header">Finance:</h6>
                                                <a className="collapse-item" href="/transactions">Transactions</a>
                                                <a className="collapse-item" href="/account-statement">Account Statement</a>
                                                <a className="collapse-item" href="/deposit">ARKM Deposit</a>
                                                <a className="collapse-item" href="/withdraw">Withdraw</a>
                                            </div>
                                        </div>
                                    </li>
                                    <hr className="sidebar-divider" />
                                    <div className="sidebar-heading">
                                        Packages
                                    </div>
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseUtilities"
                                            aria-expanded="true" aria-controls="collapseUtilities">
                                            <i className="fa fa-address-card"></i>
                                            <span>Packages</span>
                                        </a>
                                        <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                                            data-parent="#accordionSidebar">
                                            <div className="bg-white py-2 collapse-inner rounded">
                                                <h6 className="collapse-header">Packages:</h6>
                                                <a className="collapse-item" href="/purchase-your-package">Purchase Your Package</a>
                                            </div>
                                        </div>
                                    </li>
                                    <hr className="sidebar-divider" />

                                    <div className="sidebar-heading">
                                        Network
                                    </div>
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapsePages"
                                            aria-expanded="true" aria-controls="collapsePages">
                                            <i><BiNetworkChart/></i>
                                            <span>Network</span>
                                        </a>
                                        <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                                            <div className="bg-white py-2 collapse-inner rounded">
                                                <h6 className="collapse-header">Network :</h6>
                                                <a className="collapse-item" href="/two-team">Two Team</a>
                                                <a className="collapse-item" href="/level-details">Unilevel Tree</a>
                                                <a className="collapse-item" href="/direct-referrals">Direct Referrals</a>
                                                <a className="collapse-item" href="/team-view">Team View</a>
                                            </div>
                                        </div>
                                    </li>
                                    <hr className="sidebar-divider" />

                                    <li className="nav-item active">
                                        <a className="nav-link" href="/two-team-payout">
                                            <i><FaNetworkWired/></i>
                                            <span>Two Team Payout</span></a>
                                    </li>
                                    <hr className="sidebar-divider" />
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/liquidity-pool">
                                            <i><HiOutlineSpeakerphone/></i>
                                            <span>Liquidity Pool</span></a>
                                    </li>
                                    <hr className="sidebar-divider" />
                                    {/* {all_data?.username === "admin" &&
                                        <div>
                                            <div className="sidebar-heading">
                                                Administrator Settings
                                            </div>
                                            <li className="nav-item">
                                                <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapsePages3"
                                                    aria-expanded="true" aria-controls="collapsePages">
                                                    <i className="fas fa-fw fa-folder"></i>
                                                    <span>Administrator Settings</span>
                                                </a>
                                                <div id="collapsePages3" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                                                    <div className="bg-white py-2 collapse-inner rounded">
                                                        <h6 className="collapse-header">Administrator Settings :</h6>
                                                        <a className="collapse-item" href="/manage-member">Manage Member</a>
                                                        <a className="collapse-item" href="/member-subscription">Member Subscription</a>
                                                        <a className="collapse-item" href="/balance-report">Balance Report</a>
                                                        <a className="collapse-item" href="/member-transactions">Member Transactions</a>
                                                        <a className="collapse-item" href="/member-accStatement">Member Acc. Statement</a>
                                                        <a className="collapse-item" href="/withdrawal-approvals">Withdrawal Approvals</a>
                                                        <a className="collapse-item" href="/package-settings">Package Settings</a>
                                                        <a className="collapse-item" href="/referral-settings">Referral Settings</a>
                                                        <a className="collapse-item" href="/unilevel-settings">Unilevel Settings</a>
                                                        <a className="collapse-item" href="/configuration-settings">Configuration Settings</a>
                                                    </div>
                                                </div>
                                            </li>
                                            <hr className="sidebar-divider d-none d-md-block" />
                                        </div>
                                    } */}

                                </ul>
                                <div id="content-wrapper" className="d-flex flex-column">
                                    <div id="content">
                                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                                            <button onClick={disable} id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                                <i className="fa fa-bars"></i>
                                            </button>
                                            {/* <form
                                                className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                                <div className="input-group">
                                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                                                        aria-label="Search" aria-describedby="basic-addon2" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-dark" type="button">
                                                            <i className="fas fa-search fa-sm"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form> */}

                                            <ul className="navbar-nav ml-auto">

                                                <li className="nav-item dropdown no-arrow d-sm-none">
                                                    <a className="nav-link dropdown-toggle" href="/" id="searchDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fas fa-search fa-fw"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                                        aria-labelledby="searchDropdown">
                                                        <form className="form-inline mr-auto w-100 navbar-search">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control bg-light border-0 small"
                                                                    placeholder="Search for..." aria-label="Search"
                                                                    aria-describedby="basic-addon2" />
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-dark" type="button">
                                                                        <i className="fas fa-search fa-sm"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </li>
{/* 
                                                <li className="nav-item dropdown no-arrow mx-1">
                                                    <a className="nav-link dropdown-toggle" href="/" id="alertsDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fas fa-bell fa-fw"></i>
                                                        <span className="badge badge-danger badge-counter">30+</span>
                                                    </a>
                                                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                                        aria-labelledby="alertsDropdown">
                                                        <h6 className="dropdown-header">
                                                            Alerts Center
                                                        </h6>
                                                        <a className="dropdown-item d-flex align-items-center" href="/">
                                                            <div className="mr-3">
                                                                <div className="icon-circle bg-dark">
                                                                    <i className="fas fa-file-alt text-white"></i>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="small text-gray-500">December 12, 2021</div>
                                                                <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                                            </div>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="/">
                                                            <div className="mr-3">
                                                                <div className="icon-circle bg-success">
                                                                    <i className="fas fa-donate text-white"></i>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="small text-gray-500">December 7, 2021</div>
                                                                $290.29 has been deposited into your account!
                                                            </div>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="/">
                                                            <div className="mr-3">
                                                                <div className="icon-circle bg-warning">
                                                                    <i className="fas fa-exclamation-triangle text-white"></i>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="small text-gray-500">December 2, 2021</div>
                                                                Spending Alert: We've noticed unusually high spending for your account.
                                                            </div>
                                                        </a>
                                                        <a className="dropdown-item text-center small text-gray-500" href="/">Show All Alerts</a>
                                                    </div>
                                                </li> */}


                                                {/* <li className="nav-item dropdown no-arrow mx-1">
                                                    <a className="nav-link dropdown-toggle" href="/" id="messagesDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fas fa-envelope fa-fw"></i>
                                                        <span className="badge badge-danger badge-counter">7</span>
                                                    </a>
                                                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                                        aria-labelledby="messagesDropdown">
                                                        <h6 className="dropdown-header">
                                                            Message Center
                                                        </h6>
                                                        <a className="dropdown-item d-flex align-items-center" href="/">
                                                            <div className="dropdown-list-image mr-3">
                                                                <img className="rounded-circle" src="img/undraw_profile_1.svg"
                                                                    alt="..." />
                                                                <div className="status-indicator bg-success"></div>
                                                            </div>
                                                            <div className="font-weight-bold">
                                                                <div className="text-truncate">Hi there! I am wondering if you can help me with a
                                                                    problem I've been having.</div>
                                                                <div className="small text-gray-500">Emily Fowler · 58m</div>
                                                            </div>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="/">
                                                            <div className="dropdown-list-image mr-3">
                                                                <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                                                    alt="..." />
                                                                <div className="status-indicator"></div>
                                                            </div>
                                                            <div>
                                                                <div className="text-truncate">I have the photos that you ordered last month, how
                                                                    would you like them sent to you?</div>
                                                                <div className="small text-gray-500">Jae Chun · 1d</div>
                                                            </div>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="/">
                                                            <div className="dropdown-list-image mr-3">
                                                                <img className="rounded-circle" src="img/undraw_profile_3.svg"
                                                                    alt="..." />
                                                                <div className="status-indicator bg-warning"></div>
                                                            </div>
                                                            <div>
                                                                <div className="text-truncate">Last month's report looks great, I am very happy with
                                                                    the progress so far, keep up the good work!</div>
                                                                <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                                            </div>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="/">
                                                            <div className="dropdown-list-image mr-3">
                                                                <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                                                    alt="..." />
                                                                <div className="status-indicator bg-success"></div>
                                                            </div>
                                                            <div>
                                                                <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                                                                    told me that people say this to all dogs, even if they aren't good...</div>
                                                                <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                                            </div>
                                                        </a>
                                                        <a className="dropdown-item text-center small text-gray-500" href="/">Read More Messages</a>
                                                    </div>
                                                </li> */}
                                                <div className="topbar-divider d-none d-sm-block"></div>
                                                <li className="nav-item dropdown no-arrow">
                                                    <a className="nav-link dropdown-toggle" href="/" id="userDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{(all_data?.username) || " "}</span>
                                                        <i><FaUserAlt /></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                                        aria-labelledby="userDropdown">
                                                        <a className="dropdown-item" href="/profile">
                                                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                                            Profile
                                                        </a>

                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" href="/" data-toggle="modal" data-target="#logoutModal">
                                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                                            Logout
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>

                                        </nav>
                                        {props.card1}
                                        <div className="mr-5">
                                            {props.card2}
                                        </div>
                                    </div>
                                    {props.card3}
                                    {props.card4}
                                    {props.card5}
                                    {props.card6}
                                    <footer className="sticky-footer bg-white">
                                        <div className="container my-auto">
                                            <div className="copyright text-center my-auto">
                                                <span>Copyright &copy; 2023. All Rights Reserved</span>
                                            </div>
                                        </div>
                                    </footer>
                                </div>
                            </div>
                        )
                }
                <a className="scroll-to-top rounded" href="#page-top">
                    <i className="fas fa-angle-up"></i>
                </a>
                <div className="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                <a className="btn btn-dark" href="/login">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserAdmin;



