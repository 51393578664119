import React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import DepositWallet from "../Components/DepositWallet";
import "../css/my.css";
import "../css/password.css"
import { CSVLink } from "react-csv";
const URL = "https://dev.arkmnetwork.com/api/app/manual-deposit";

const Deposit = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [search, setSearch] = useState('');
    const [access_token, setaccess_token] = useState('');
    const [records, setRecords] = useState(item);


    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.data.depositRequest.data);
        setFilteredItem(response.data.depositRequest);
        console.log(item)
    }

    const columns = [
        {
            name: <h6>Date</h6>,
            selector: row => row.updated_at,
            sortable: true,

        },
        {
            name: <h6>Referance No</h6>,
            selector: row => row.transaction_id,
            sortable: true,
        },
        {
            name: <h6>Amount</h6>,
            selector: row => <div>{parseFloat(row.amount).toFixed(0) || ''} <span>ARKM</span></div>,
            sortable: true,
        },
        {
            name: <h6>Token Price</h6>,
            selector: row => <div> <span>$ </span>{parseFloat(row.token_price).toFixed(2) || ''}</div>,
            sortable: true,
        },

        {
            name: <h6>Received Amount	</h6>,
            selector: row => <div> <span>$ </span>{parseFloat(row.received_amount).toFixed(2) || ''}</div>,
            sortable: true,
        },
        {
            name: <h6>Status</h6>,
            // cell: (row) => (<div> {row.status == 0 ? (<span >Pending</span>) : (<span>Approved</span>)} </div>),
            selector: row => row.status,
            sortable: true,
            cell: (row) => (
                <div>
                    <div className="text-warning">{row.status == '0' && 'Pending'}</div>
                    <div className="text-success">{row.status == '1' && 'Approved'}</div>
                    <div className="text-danger">{row.status == '2' && 'Rejected'}</div>
                </div>
            ),
        },
        {
            name: <h6>Remarks	</h6>,
            selector: row =>row.comment,
            sortable: true,
        },
       
    ]

    useEffect(() => {
        if (item.length === 0 && filteredItem.length === 0) {
            getdata();
        }

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    useEffect(() => {
       const result = filteredItem.filter(data=>{
        return data.transaction_id.toLowerCase().match(search.toLowerCase());
       })
       setRecords(result)
    }, [search]);

    // function handleFilter(e){
    //     const newData = records.filter(row=>{
    //         return row.name.toLowerCase().includes(e.target.value.toLowerCase())
    //     })
    //     setRecords({newData})
    // }

    return (
        <>
            <UserAdmin
                card1={
                    <DepositWallet />
                }
                
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                        <DataTable
                            title={<h5 className="mt-4">Deposit</h5>}
                            columns={columns}
                            data={item}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            selectableRow
                            selectableRowsHighlight
                            highlightOnHover

                            actions={
                                <div className="d-flex" >
                                    <input type="text" placeholder="Search here"
                                        className="w-60 mt-3 mr-2 form-control"
                                        onChange={(e)=> setSearch(e.target.value)} value={search}
                                    />
                                    <CSVLink data={item} filename="Deposit.csv">
                                        <button className="btn btn-md mr-2 btn-dark mt-3">Export</button>
                                    </CSVLink>
                                </div>
                            }
                        />
                    </div>
                }
            />
        </>
    )
}
export default Deposit;