import React from "react";
import "react-treeview/react-treeview.css";
import Card from 'react-bootstrap/Card';
import { FaBox, FaRegMoneyBillAlt } from 'react-icons/fa';
import { BsSnow, BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';
import { AiOutlineTeam } from 'react-icons/ai';
import { GiCutDiamond } from 'react-icons/gi';
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Tree, TreeNode, } from 'react-organizational-chart';
import styled from 'styled-components'
import axios from 'axios';
import RankDetail from "./RankDetail";
import { FaUserAlt } from 'react-icons/fa';


const URL2 = "https://dev.arkmnetwork.com/api/app/network/genealogy";
const URL1 = "https://dev.arkmnetwork.com/api/app/user-team-details";

const StyledNode = styled.div`
padding: 5px;
border-radius: 8px;
display: inline-block;
border: 1px solid gray;
`;


const TwoTeam1 = () => {
    const [item, setItem] = useState([]);
    const [item2, setItem2] = useState([]);
    const [username, setUsername] = useState([]);
    const [access_token, setaccess_token] = useState();

    const [t1, setT1] = useState([]);
    const [t2, setT2] = useState([]);
    const [t3, setT3] = useState([]);
    const [t4, setT4] = useState([]);

    const [l2, setL2] = useState([]);    
    const [l2a, setL2a] = useState([]);    
    const [l2b, setL2b] = useState([]);
    
    const [l3aa, setL3aa] = useState([]);    
    const [l3ab, setL3ab] = useState([]);
    const [l3ba, setL3ba] = useState([]);    
    const [l3bb, setL3bb] = useState([]);

    const [l4aaa, setL4aaa] = useState([]);    
    const [l4aab, setL4aab] = useState([]);
    const [l4aba, setL4aba] = useState([]);    
    const [l4abb, setL4abb] = useState([]);
    const [l4baa, setL4baa] = useState([]);    
    const [l4bab, setL4bab] = useState([]);
    const [l4bba, setL4bba] = useState([]);    
    const [l4bbb, setL4bbb] = useState([]);
    const [paid, setPaid]= useState([]);



    const getItem2 = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL2}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata2 = async () => {
        let response = await getItem2();
        setItem(response.data);
    }

    const getItem1 = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL1}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata1 = async () => {
        let response = await getItem1();
        setItem2(response.data.downlineDetails);
        setT1(response.data.downlineDetails[0][0])       
        setT2(response.data.downlineDetails[1])       

        setL2a(t2.filter(data => data.position == "L"));
        for(var i=0;i<l2a.length;i++){
            console.log(l2a[i].mb_id)
        };
        setL2b(t2.filter(data => data.position == "R"));
        for(var i=0;i<l2b.length;i++){
            console.log(l2b[i].mb_id)

            // if(l3ab[0].is_paid.length === 0 ){
            //     setPaid("Not Opted")
            // }else if(l3ab[0]?.is_paid === true){
            //     setPaid("Paid")
            // }else{
            //     setPaid("Unpaid")
            // }

        };
       

        setT3(response.data.downlineDetails[2])
        setT4(response.data.downlineDetails[3])


        setL3aa(t3.filter(data => data.position == "L" && data.boss == l2a[0].key));
        for(var i=0;i<l3aa.length;i++){
            console.log(l3aa[i].mb_id)
        };

        setL3ab(t3.filter(data => data.position == "R" && data.boss == l2a[0]?.key));
        for(var i=0;i<l3ab.length;i++){
            console.log(l3ab[i].mb_id)

        };
        console.log(l3ab)

        setL3ba(t3.filter(data => data.position == "L" && data.boss == l2b[0].key));
        for(var i=0;i<l3ba.length;i++){
            console.log(l3ba[i].mb_id)
        };

        setL3bb(t3.filter(data => data.position == "R" && data.boss == l2b[0]?.key));
        for(var i=0;i<l3bb.length;i++){
            console.log(l3bb[i].mb_id)
        };


        // 

        setL4aaa(t4.filter(data => data.position == "L" && data.boss == l3aa[0].key));
        for(var i=0;i<l4aaa.length;i++){
            console.log(l4aaa[i].mb_id)
        };

        setL4aab(t4.filter(data => data.position == "R" && data.boss == l3aa[0]?.key));
        for(var i=0;i<l4aab.length;i++){
            console.log(l4aab[i].mb_id)
        };
        setL4aba(t4.filter(data => data.position == "L" && data.boss == l3ab[0].key));
        for(var i=0;i<l4aba.length;i++){
            console.log(l4aba[i].mb_id)
        };

        setL4abb(t4.filter(data => data.position == "R" && data.boss == l3ab[0]?.key));
        for(var i=0;i<l4abb.length;i++){
            console.log(l4abb[i].mb_id)
        };
        setL4baa(t4.filter(data => data.position == "L" && data.boss == l3ba[0].key));
        for(var i=0;i<l4baa.length;i++){
            console.log(l4baa[i].mb_id)
        };

        setL4bab(t4.filter(data => data.position == "R" && data.boss == l3ba[0]?.key));
        for(var i=0;i<l4bab.length;i++){
            console.log(l4bab[i].mb_id)
        };
        setL4bba(t4.filter(data => data.position == "L" && data.boss == l3bb[0].key));
        for(var i=0;i<l4bba.length;i++){
            console.log(l4bba[i].mb_id)
        };

        setL4bbb(t4.filter(data => data.position == "R" && data.boss == l3bb[0]?.key));
        for(var i=0;i<l4bbb.length;i++){
            console.log(l4bbb[i].mb_id)
        };

    }

    useEffect(() => {
        if (item.length == 0) {
            getdata2();
        }

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, [item]);

    // useEffect(() => {
    //     if (t2.length == 0){
    //     getdata1();
    //     }

    //     if (l2a.length == 0 && l2b.length == 0){
    //      getdata1();
    //     }

    //     if (l3aa.length == 0 && l3ab.length == 0 && l3ba.length == 0 && l3bb.length == 0 ){
    //         getdata1();
    //     }
    //     if (l4aaa.length == 0 && l4aab.length == 0 && l4aba.length == 0 && l4abb.length == 0 && l4baa.length == 0 && l4bab.length == 0 && l4bba.length == 0 && l4bbb.length == 0 ){
    //         getdata1();
    //     }


    //     console.log(t4)

    //     let access_token = localStorage.getItem('access_token');
    //     setaccess_token(access_token);
    // }, [t2,l2a,l2b,l3aa,l3ab,l3ba,l3bb,l4aaa,l4aab,l4aba,l4abb,l4baa,l4bab,l4bba,l4bbb]);

    useEffect(() => {
        

        getdata1();
        setTimeout(() => {
            getdata1()
        }, 3000);

        if(t1.length == 0 && t2.length == 0 && t3.length == 0 && t4.length == 0){
            getdata1()

        }
        
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, [t1,t2,t3,t4]);

    // const user = async (data) => {
    //     let access_token = localStorage.getItem('access_token');
    //     try {
    //         var result = await axios.post(`${URL}`+ props.username + "&search=true", data, {
    //             headers: {
    //                 "Authorization": "Bearer " + access_token,
    //             },
    //         })
    //         console.log(result)
    //         return  result.data;
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // async function sent() {
    //     let item = {username};
    //     console.log(item);

    //     let result = await user(item)

    //     if (result.status == true) {
    //          toast.info(result.msg, {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //             });
    //     } else {
    //         if (result.hasOwnProperty('errors')) {
    //             if (result.errors.hasOwnProperty('email')) {
    //                  toast.info(result.errors.email[0], {
    //                     position: "top-right",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                     });
    //             }
    //         } else {
    //              toast.info(result.msg, {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //                 });
    //         }

    //     }
    // }

    return (
        <>
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <Card>
                                <Card.Body className="bg-dark text-light">
                                    <i className="float-right fas fa-2x gray"><GiCutDiamond /></i>
                                    <Card.Title>Total Referrals</Card.Title>
                                    <Card.Subtitle className="mb-2">{(item?.totalReferral) || " "}</Card.Subtitle>

                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <Card>
                                <Card.Body className="bg-dark text-light">
                                    <i className="float-right fas fa-2x gray"><BsSnow /></i>
                                    <Card.Title>Paid Referrals</Card.Title>
                                    <Card.Subtitle className="mb-2">{(item?.paidReferral) || " "}</Card.Subtitle>

                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <Card>
                                <Card.Body className="bg-dark text-light">
                                    <i className="float-right fas fa-2x gray"><AiOutlineTeam /></i>
                                    <Card.Title>Total Team</Card.Title>
                                    <Card.Subtitle className="mb-2">{(item?.totalTeam) || ' '}</Card.Subtitle>

                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <Card>
                                <Card.Body className="bg-dark text-light">
                                    <i className="float-right fas fa-2x gray"><BsFillArrowLeftSquareFill /></i>
                                    <Card.Title>Left Team</Card.Title>
                                    <Card.Subtitle className="mb-2">{(item?.leftTeam) || " "}</Card.Subtitle>

                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <Card>
                                <Card.Body className="bg-dark text-light">
                                    <i className="float-right fas fa-2x gray"><BsFillArrowRightSquareFill /></i>
                                    <Card.Title>Right Team</Card.Title>
                                    <Card.Subtitle className="mb-2">{(item?.rightTeam) || " "}</Card.Subtitle>

                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <Card>
                                <Card.Body className="bg-dark text-light">
                                    <i className="float-right fas fa-2x gray"><FaRegMoneyBillAlt /></i>
                                    <Card.Title>Total Package</Card.Title>
                                    <Card.Subtitle className="mb-2">{parseFloat(item?.totalPackage).toFixed(2) || '0'}</Card.Subtitle>

                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <Card>
                                <Card.Body className="bg-dark text-light">
                                    <i className="float-right fas fa-2x gray"><FaBox /></i>
                                    <Card.Title>Two Team Bonus</Card.Title>
                                    <Card.Subtitle className="mb-2">{parseInt(item?.twoTeamBonus).toFixed(2) || " "}</Card.Subtitle>

                                </Card.Body>
                            </Card>
                        </div>

                    </div>
                </div>

            </div>
            {/* <RankDetail/> */}
            <div className="container-fluid mt-3 mb-3 ">
                <div className="row ">
                    <div className="col-12 col-md-12 mt-5">
                        <div className="card ">
                            <div className="card-header bg-dark text-light">
                                Two Team
                            </div>
                            <div className=" card-body d-flex">

                                <div className="col-6 ">
                                    <label for="exampleInputEmail1">Username</label>
                                    <input
                                        className="form-control  mb-3"
                                        type="text"
                                        placeholder="Search by Username"
                                        name="username"
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>

                                <div className="col-6 mt-3 pt-3">
                                    <button onClick={getdata2} type="button" className="btn btn-dark btn-block">Search</button>
                                </div>

                            </div>
                            <div className="col-12  mb-5 mt-5">

                                <Tree
                                    lineWidth={'2px'}
                                    lineColor={'gray'}
                                    lineBorderRadius={'10px'}
                                    label={<StyledNode><img alt="" className="" width="80" height="100" src={`https://dev.arkmnetwork.com/en/avatars/${(t1.avatar)}`} /><br></br><span>{(t1.mb_id)}</span><br></br><span>{((t1.is_paid) === true) ? "Paid" : "Unpaid"}</span><br></br><span>{(t1.package_name)}</span></StyledNode>}
                                >
                                    <TreeNode label={<StyledNode><img alt="" className="" width="80" height="100" src={(`https://dev.arkmnetwork.com/en/avatars/${(l2a[0]?.avatar)}`) === "https://dev.arkmnetwork.com/en/avatars/no-image.png" && "userw.png"} /><br></br><span> {l2a[0]?.mb_id}</span><br></br><span>{((l2a[0]?.is_paid) === true) ? "Paid" : "Unpaid"}</span><br></br><span>{(l2a[0]?.package_name)}</span></StyledNode>}>
                                        
                                    <TreeNode label={<StyledNode><img alt="" className="" width="80" height="100" src={(`https://dev.arkmnetwork.com/en/avatars/${(l3aa[0]?.avatar)}`) === "https://dev.arkmnetwork.com/en/avatars/no-image.png" && "userw.png"} /><br></br><span>{(l3aa[0]?.mb_id) ? (l3aa[0]?.mb_id): "Vacant" }</span><br></br><span>{(((l3aa[0]?.is_paid) === null) ? "Not Opted" :  ((l3aa[0]?.is_paid) === true) ? "Paid" : "Unpaid" )  }</span><br></br><span>{(l3aa[0]?.package_name)? (l3aa[0]?.package_name): "Not Available"}</span></StyledNode>} >
                                            <TreeNode label={<StyledNode><img alt="" className="" width="80" height="100" src="userw.png" /><br></br><span>Vacant</span><br></br><span>Not Opted</span><br></br><span>Not Available</span></StyledNode>} />
                                            <TreeNode label={<StyledNode><img alt="" className="" width="80" height="100" src="userw.png" /><br></br><span>Vacant</span><br></br><span>Not Opted</span><br></br><span>Not Available</span></StyledNode>} />
                                        </TreeNode>
                                        <TreeNode label={<StyledNode><img alt="" className="" width="80" height="100" src={(`https://dev.arkmnetwork.com/en/avatars/${(l3ab[0]?.avatar)}`) === "https://dev.arkmnetwork.com/en/avatars/no-image.png" && "userw.png"} /><br></br><span>{ (l3ab[0]?.mb_id) ? (l3ab[0]?.mb_id) : "Vacant" } </span><br></br><span>{( ((l3ab[0]?.is_paid) === null) ? "Not Opted" : ( ((l3ab[0]?.is_paid) === true) ? "Paid" : "Unpaid" ) )}</span><br></br><span>{(l3ab[0]?.package_name)? (l3ab[0]?.package_name): "Not Available"}</span></StyledNode>} >
                                            <TreeNode label={<StyledNode><img alt="" className="" width="80" height="100" src="userw.png" /><br></br><span>Vacant</span><br></br><span>Not Opted</span><br></br><span>Not Available</span></StyledNode>} />
                                            <TreeNode label={<StyledNode><img alt="" className="" width="80" height="100" src="userw.png" /><br></br><span>Vacant</span><br></br><span>Not Opted</span><br></br><span>Not Available</span></StyledNode>} />
                                        </TreeNode>
                                    </TreeNode>


                                    <TreeNode label={<StyledNode><img className="" alt="" width="80" height="100" src={(`https://dev.arkmnetwork.com/en/avatars/${(l2b[0]?.avatar)}`) === "https://dev.arkmnetwork.com/en/avatars/no-image.png" && "userw.png"} /><br></br><span>{l2b[0]?.mb_id}</span><br></br><span>{((l2b[0]?.is_paid) === true) ? "Paid" : "Unpaid"}</span><br></br><span>{(l2b[0]?.package_name)}</span></StyledNode>}>
                                        
                                    <TreeNode label={<StyledNode><img alt="" className="" width="80" height="100" src={(`https://dev.arkmnetwork.com/en/avatars/${(l3ba[0]?.avatar)}`) === "https://dev.arkmnetwork.com/en/avatars/no-image.png" && "userw.png"} /><br></br><span>{(l3ab[0]?.mb_id)?(l3ab[0]?.mb_id): "Vacant" }</span><br></br><span>{(((item2?.is_paid) === true) ? "Paid" : "Unpaid") && "Not Opted"}</span><br></br><span>{(item2?.package_name)? (item2?.package_name): "Not Available"}</span></StyledNode>} >
                                            <TreeNode label={<StyledNode><img className="" alt="" width="80" height="100" src="userw.png" /><br></br><span>Vacant</span><br></br><span>Not Opted</span><br></br><span>Not Available</span></StyledNode>} />
                                            <TreeNode label={<StyledNode><img className="" alt="" width="80" height="100" src="userw.png" /><br></br><span>Vacant</span><br></br><span>Not Opted</span><br></br><span>Not Available</span></StyledNode>} />
                                        </TreeNode>
                                        <TreeNode label={<StyledNode><img alt="" className="" width="80" height="100" src={(`https://dev.arkmnetwork.com/en/avatars/${(l3bb[0]?.avatar)}`) === "https://dev.arkmnetwork.com/en/avatars/no-image.png" && "userw.png"} /><br></br><span>{(l3ab[0]?.mb_id)?(l3ab[0]?.mb_id): "Vacant" }</span><br></br><span>{(((item2?.is_paid) === true) ? "Paid" : "Unpaid") && "Not Opted"}</span><br></br><span>{(item2?.package_name)? (item2?.package_name): "Not Available"}</span></StyledNode>} >
                                            <TreeNode label={<StyledNode><img className="" alt="" width="80" height="100" src="userw.png" /><br></br><span>Vacant</span><br></br><span>Not Opted</span><br></br><span>Not Available</span></StyledNode>} />
                                            <TreeNode label={<StyledNode><img className="" alt="" width="80" height="100" src="userw.png" /><br></br><span>Vacant</span><br></br><span>Not Opted</span><br></br><span>Not Available</span></StyledNode>} />
                                        </TreeNode>
                                    </TreeNode>

                                </Tree>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default TwoTeam1;