import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import axios from 'axios';
import moment from 'moment';

const URL = "https://dev.arkmnetwork.com/api/app/user-liquidity-details";


const LiquidityPool = () => {
    const [item, setItem] = useState();
    const [filteredItem, setFilteredItem] = useState();
    const [access_token, setaccess_token] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);


    const [username, setUsername] = useState();
    const [status, setStatus] = useState();

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getItem = async (page) => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }
    const getdata = async (page) => {
        let response = await getItem(page);
        console.log(response);
        setItem(response.data.liquidityDetails.data);
        // setFilteredItem(response.data.liquidityDetails.data);
        console.log(item)

        setLimit(response.data.liquidityDetails.per_page);
        setTotalPages(response.data.liquidityDetails.last_page);

        if (username && status) {
            setFilteredItem(
                response.data.liquidityDetails.data.filter(item => item.username === username && item.status === status))
        }
        else if (username) {
            setFilteredItem(
                response.data.liquidityDetails.data.filter(item => item.username === username))
        }
        else if (status) {
            setFilteredItem(
                response.data.liquidityDetails.data.filter(item => item.status === status))
        }
        else{
            setFilteredItem(response.data.liquidityDetails.data);
        }

    }

    const columns = [
        {
            name: <h6>Total Bonus</h6>,
            selector: row => <div className=""><b>$ </b>{parseFloat(row.total_amount).toFixed(2)}</div>,
            sortable: true,

        },
        {
            name: <h6>Paid Bonus</h6>,
            selector: row => <div className=""><b>$ </b>{parseFloat(row.paid_bonus).toFixed(2)}</div>,
            sortable: true,
        },
        {
            name: <h6>Bonus Date</h6>,
            selector: row => row.bonus_date,
            sortable: true,
            cell: (row) => (
                <div>
                    {moment(row.bonus_date).format("MMMM Do YYYY")}
                </div>
            ),
        },
        {
            name: <h6>Status</h6>,
            cell: (row) => (<div> {row.status == 1 ? (<span >Active</span>) : (<span>Inactive</span>)} </div>),
            selector: row => row.status,
            sortable: true,
        },
        {
            name: <h6>Created Date</h6>,
            selector: row => row.created_at,
            sortable: true,
            cell: (row) => (
                <div>
                    {moment(row.created_at).format("MMMM Do YYYY")}
                </div>
            ),
        },

    ]

    const handleusername=(data)=>{
        setUsername(data)
    }

    const handlestatus=(data)=>{
        setStatus(parseInt(data))
    }

    useEffect(() => {
        // setTimeout(() => {
        //     getdata();
        // }, 1000);
        getdata();

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    useEffect(() => {
        getdata(currentPage);
    }, [currentPage,username,status]);
    return (
        <>
            <UserAdmin
                card1={
                    <div className="card ml-4 mr-4 mb-5 mt-5">
                        <div className="card-header">Member Account Statement</div>
                        <form className="ml-5 mr-5 mt-4 mb-5">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Username</label>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="inputEmail4"
                                    name="username"  
                                    onChange={(event) => handleusername(event.target.value)}  
                                    placeholder="Username" />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Type</label>
                                    <select id="inputState" 
                                    name="status"
                                    onChange={(event) => handlestatus(event.target.value)}  className="form-control">
                                        <option value="" selected>All</option>
                                        <option value="1">Unblocked</option>
                                        <option value="0">Blocked</option>
                                    </select>
                                </div>
                            </div>
                            {/* <button type="button" class="btn btn-dark btn-block">Search</button> */}

                        </form>
                    </div>
                }
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">

                        <DataTable
                            title={<h5 className="mt-4">Deposit</h5>}
                            columns={columns}
                            data={filteredItem}
                            pagination
                            paginationServer
                            paginationTotalRows={totalPages * limit}
                            paginationPerPage={limit}
                            onChangePage={handlePageChange}

                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            selectableRow
                            selectableRowsHighlight
                            highlightOnHover
                            actions={
                                <button className="btn btn-md btn-dark mt-3">Export</button>
                            }
                        // subHeaderComponent={
                        //   <input type="text" 
                        //   placeholder="Search here" 
                        //   className="w-25 form-control" 
                        //   value={search} 
                        //   onChange={(e)=>setSearch(e.target.value)}

                        //   /> 
                        // }
                        // subHeaderAlign="right"
                        />
                    </div>
                } />

        </>
    )
}
export default LiquidityPool;