import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
// import Profile1 from "../Components/Profile1";
import Profile2 from "../Components/Profile2";
const Profile=()=>{
    return (
        <>
         <UserAdmin
            
            card1={
                <Profile2 />
            }
            
        />
        </>
    );
}
export default Profile;