import React from "react";

const Managesubscription = () => {
    return (

        <>
            <div className="card ml-4 mr-4 mb-5 mt-5">
                <div className="card-header">Manage Subscription</div>
                <form className="ml-5 mr-5 mt-4 mb-4">
                    <div className="form-row">
                        <div className="form-group col-md-7">
                            <label for="exampleInputEmail1">User Id</label>
                            <input class="form-control " type="text" placeholder="User Id"/>
                        </div>
                        <div className="form-group col-md-5 mt-2">
                            <button type="button" className="btn btn-dark btn-block mt-4">Search</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default Managesubscription;
