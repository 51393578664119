import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState,useEffect } from "react";
import axios from 'axios';
import { CSVLink } from "react-csv";
import moment from 'moment';

const URL = "https://dev.arkmnetwork.com/api/app/network/team-view";

const TeamView = () => {
    const [item, setItem] = useState([]);
    const [access_token, setaccess_token] = useState('');
    const [filteredItem, setFilteredItem] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getItem = async (page) => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}?page=${page}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async (page) => {
        let response = await getItem(page);
        console.log(response);
        setItem(response.data.users.data);
        setFilteredItem(response.data.users.data);
        console.log(item)
        setLimit(response.data.users.per_page);
        setTotalPages(response.data.users.last_page);
    }

    const columns = [
        {
            name: <h6>ID</h6>,
            selector: row => row.user_id,
            sortable: true,
        },
        {
            name: <h6>User ID</h6>,
            selector: row => row.username,
            sortable: true,
        },
        {
            name: <h6>Sponsor Id</h6>,
            selector: row => row.sponsor_name,
            sortable: true,
        },
        {
            name: <h6>Joining Level</h6>,
            selector: row => row.level,
            sortable: true,
        },
        {
            name: <h6>Position</h6>,
            selector: row => row.leg_position,           
            sortable: true,
        },
        {
            name: <h6>Active Package</h6>,
            selector: row => row.package_name,
            sortable: true,
            cell: (row) => (
                <div>
                    {row.package_name === 'null' && 'Not Available'}
                    {row.package_name == 'Package 101 - 500' && 'Package 101 - 500'}
                    {row.package_name == 'Package 101 - 500 USD' && 'Package 101 - 500 USD'}
                    {row.package_name == 'Package 501 - 2500 USD' && 'Package 501 - 2500 USD'}
                    {row.package_name == 'Package 2501 - 10000 USD' && 'Package 2501 - 10000 USD'}
                    {row.package_name == 'Package 10001 - Above USD' && 'Package 10001 - Above USD'}
                  
                </div>
            ),
        },
        {
            name: <h6>Upline Position</h6>,
            selector: row => <div><b className="text-xs">{( row.leg_position)}</b><br></br><span>{(row.parent_name)}</span></div>,
            sortable: true,
        },
        {
            name: <h6>Registration Date</h6>,
            selector: row => row.created_at,           
            sortable: true,
            cell: (row) => (
                <div>
                    {moment(row.created_at).format("MMMM Do YYYY")}
                </div>
            ),
        },
    ]
    
    useEffect(() => {
        // setTimeout(() => {
            getdata();
        // }, 1000);

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    useEffect(() => {
        getdata(currentPage);
    }, [currentPage]);

    return (
        <>

            <UserAdmin
               
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                        <DataTable
                            title={<h5 className="mt-4">Team View</h5>}
                            columns={columns}
                            data={filteredItem}
                            pagination
                            paginationServer
                            paginationTotalRows={totalPages * limit}
                            paginationPerPage={limit}
                            onChangePage={handlePageChange}

                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            selectableRow
                            selectableRowsHighlight
                            highlightOnHover
                            actions={
                                <CSVLink data={filteredItem} filename="Team_View.csv">
                                    <button className="btn btn-md btn-dark mt-3">Export</button>
                                </CSVLink>
                            }
                        />
                    </div>
                }
            />
        </>
    )
}
export default TeamView;
