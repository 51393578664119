import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../css/my.css";
import useClipboard from "react-use-clipboard";

const URL2 = "https://dev.arkmnetwork.com/api/app/manual-deposit-store?";
const URL3 = "https://dev.arkmnetwork.com/api/app/manual-deposit";

const DepositWallet = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');
    const [transaction_id, SetTransaction_id] = useState("");
    const [investment_amount, SetInvestment_amount] = useState("");
    const [comment, SetComment] = useState("");
    const [DB,SetDB] = useState([])
    const [arkm,SetArkm] = useState([])
    const [isCopied, setCopied] = useClipboard(arkm?.coinAddress);

    const postData = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL2}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                   
                },
                
            })
            console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function transaction() {
        let item = { transaction_id,investment_amount, comment};
        console.log(transaction_id);

        let result = await postData(item)

        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('investment_amount')) {
                    toast.info(result.errors.investment_amount[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } if (result.errors.hasOwnProperty('transaction_id')) {
                    toast.info(result.errors.transaction_id[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL3}`,
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        SetArkm(response.data.data);
        SetDB(response.data.data)
   }


    useEffect(() => {
        // if (item.length === 0 && filteredItem.length === 0) {
            getdata();
        // }
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    return (
        <>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                Deposit Wallet
                            </div>
                            <div className="card-body mt-2">
                                <h5 className="card-title">Balance :</h5>
                                <p className="card-text">${parseFloat(DB?.depositBalance).toFixed(2) || '0.00'}</p>

                            </div>
                        </div>
                    </div>
                    <div className=" col-md-12">
                        <div className="card">
                            <div className="card-header">
                                Generate ARKM Deposit Request
                            </div>
                            <div className="card-body">

                                <form>
                                    Enter Token Amount :<input
                                        class="form-control mb-3"
                                        type="text"
                                        placeholder="Enter Token Amount"
                                        name="investment_amount"
                                        onChange={(e) => SetInvestment_amount(e.target.value)}
                                    />
                                    TNX ID :<input
                                        class="form-control mb-3"
                                        type="text"
                                        placeholder="Enter transaction id"
                                        name="transaction_id"
                                        onChange={(e) => SetTransaction_id(e.target.value)}
                                    />
                                    Comment :<input
                                            class="form-control mb-3"
                                            type="text"
                                            placeholder="Enter comment"
                                            name="comment"
                                            onChange={(e) => SetComment(e.target.value)}
                                        />
                                    <a onClick={transaction} href="#" className="btn btn-dark">Submit</a>
                                    <ToastContainer />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 mb-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">

                            <div className="card-header">
                            ARKM Deposit (1 Token = ${parseFloat(arkm?.token_price).toFixed(2) || '0.00'})
                            </div>
                            <div className="flex">
                                <div className="col-12 col-md-6 card-body">
                                    <img src="https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=0x51f67ecd0bd9be53df41e6530dc64fd3d1732bdf" className="qr w-100 ml-1" ></img>
                                </div>

                                <div className="col-12 col-md-6 mt-5 pt-4">
                                    <p style={{ width: "240px" }} className="w-100">{(arkm?.coinAddress)}</p>
                                    <button onClick={setCopied} className="btn btn-dark">Copy Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DepositWallet;
