import React from "react";
import Card from 'react-bootstrap/Card';
import { TbCertificate } from 'react-icons/tb';
import { BiNetworkChart } from 'react-icons/bi';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { GiMoneyStack, GiReceiveMoney, GiCoins, GiTakeMyMoney, GiCutDiamond } from 'react-icons/gi';
import { FaUserAlt, FaAward, FaRegMoneyBillAlt,FaNetworkWired } from 'react-icons/fa';
import { AiOutlineBarChart, AiOutlineTeam } from 'react-icons/ai';
import { BsFillGiftFill, BsFillCalendarDateFill, BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from 'react-icons/bs';
import ScaleLoader from "react-spinners/ScaleLoader";
import { MdDateRange } from 'react-icons/md';

import { useState,CSSProperties,useEffect  } from "react";
import axios from 'axios';
import ReferralLink from "./ReferralLink";
import "../../vendor/fontawesome-free/css/all.min.css";
import "../../css/sb-admin-2.min.css";
import "../../css/my.css";
import { PieChart } from 'react-minimal-pie-chart';

const mystyle = {
    margin: "auto",
    verticalAlign: "middle",
    marginTop: "280px",
    marginLeft:"50%"

};

function disable() {
    if (document.getElementById('accordionSidebar').style.display === 'block') {
        document.getElementById('accordionSidebar').style.display = 'none'
    } else {
        document.getElementById('accordionSidebar').style.display = 'block'
    }
}

const chartData = [
    {
        title: "Completed",
        value: 80,
        color: "#E38627",
        key: 1
    },
    {
        title: "active",
        value: 20,
        color: "#C13C37",
        key: 2
    }
];

const Dashboard1 = () => {
    const [all_data, setAllData] = useState({});
    const [access_token, setaccess_token] = useState('');
    const [users, setUsers] = useState({});
    const [loading, setLoading] = useState(false)

    const getUser = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get("https://dev.arkmnetwork.com/api/app/dashboard",
                {
                    headers: {
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    const getAllUsers = async () => {
        let response = await getUser();
        setUsers(response.data.data);
        setAllData(response.data.user);
        console.log(users)
    }

    useEffect(() => {
        getAllUsers();
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 5000);

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    return (
        <>
            <div>
                {
                    loading ?
                        (<ScaleLoader 
                            color={'gray'}
                            loading={loading}
                            cssOverride={mystyle}
                            size={100}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        // isVisible={true}
                        />) : (

                            <div id="wrapper">

                                <ul className="navbar-nav bg-dark sidebar sidebar-dark accordion" id="accordionSidebar">
                                    <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                                        <div className="sidebar-brand text mx3">
                                            <img src="logo.png" alt="logo" className="mt-0.5 logo"></img>
                                        </div>
                                        <div className="sidebar-brand-icon">
                                            <img src="logo.png" alt="logo" className="mt-1 fas logo1"></img>
                                        </div>
                                    </a>
                                    <hr className="sidebar-divider my-0" />
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/">
                                            <i className="fa fa-home"></i>
                                            <span>Dashboard</span></a>
                                    </li>
                                    <hr className="sidebar-divider" />
                                    <div className="sidebar-heading">
                                        Account
                                    </div>
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseTwo"
                                            aria-expanded="true" aria-controls="collapseTwo">
                                            <i className="h3" aria-hidden="true"><AiOutlineBarChart /></i>
                                            <span>Finance</span>
                                        </a>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                            <div className="bg-white py-2 collapse-inner rounded">
                                                <h6 className="collapse-header">Finance:</h6>
                                                <a className="collapse-item" href="/transactions">Transactions</a>
                                                <a className="collapse-item" href="/account-statement">Account Statement</a>
                                                <a className="collapse-item" href="/deposit">ARKM Deposit</a>
                                                <a className="collapse-item" href="/withdraw">Withdraw</a>
                                            </div>
                                        </div>
                                    </li>
                                    <hr className="sidebar-divider" />
                                    <div className="sidebar-heading">
                                        Packages
                                    </div>
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapseUtilities"
                                            aria-expanded="true" aria-controls="collapseUtilities">
                                            <i className="fa fa-address-card"></i>
                                            <span>Packages</span>
                                        </a>
                                        <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                                            data-parent="#accordionSidebar">
                                            <div className="bg-white py-2 collapse-inner rounded">
                                                <h6 className="collapse-header">Packages:</h6>
                                                <a className="collapse-item" href="/purchase-your-package">Purchase Your Package</a>
                                            </div>
                                        </div>
                                    </li>
                                    <hr className="sidebar-divider" />

                                    <div className="sidebar-heading">
                                        Network
                                    </div>
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#collapsePages"
                                            aria-expanded="true" aria-controls="collapsePages">
                                            <i><BiNetworkChart/></i>
                                            <span>Network</span>
                                        </a>
                                        <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                                            <div className="bg-white py-2 collapse-inner rounded">
                                                <h6 className="collapse-header">Network :</h6>
                                                <a className="collapse-item" href="/two-team">Two Team</a>
                                                <a className="collapse-item" href="/direct-referrals">Unilevel Tree</a>
                                                <a className="collapse-item" href="/direct-referrals">Direct Referrals</a>
                                                <a className="collapse-item" href="/team-view">Team View</a>
                                            </div>
                                        </div>
                                    </li>
                                    <hr className="sidebar-divider" />

                                    <li className="nav-item active">
                                        <a className="nav-link" href="/two-team-payout">
                                            <i ><FaNetworkWired/></i>
                                            <span>Two Team Payout</span></a>
                                    </li>
                                    <hr className="sidebar-divider" />
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/liquidity-pool">
                                            <i ><HiOutlineSpeakerphone/></i>
                                            <span>Liquidity Pool</span></a>
                                    </li>
                                    <hr className="sidebar-divider" />
                                    
                                </ul>
                                <div id="content-wrapper" className="d-flex flex-column">
                                    <div id="content">
                                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                                            <button onClick={disable} id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                                <i className="fa fa-bars"></i>
                                            </button>
                                            

                                            <ul className="navbar-nav ml-auto">
                                                
                                                <div className="topbar-divider d-none d-sm-block"></div>
                                                <li className="nav-item dropdown no-arrow">
                                                    <a className="nav-link dropdown-toggle" href="/" id="userDropdown" role="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{(all_data?.username) || " "}</span>
                                                        <i><FaUserAlt /></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                                        aria-labelledby="userDropdown">
                                                        <a className="dropdown-item" href="/profile">
                                                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                                            Profile
                                                        </a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item" href="/" data-toggle="modal" data-target="#logoutModal">
                                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                                            Logout
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                        <div className="container-fluid">
                                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                                <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-3 col-md-6 mb-4 ">
                                                    <div className="card border-left-primary shadow h-100 py-2 ">
                                                        <div className="card-body box2s">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        Deposite Wallet</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Balance</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.deposit_wallet).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-wallet fa-2x gray"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-secondary shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        Withdraw Wallet</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Balance</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.withdraw_wallet).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas  fa-2x gray"><GiTakeMyMoney /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-success shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        TOKEN WALLET</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Balance</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.token_wallet).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-2x gray"><BsFillGiftFill /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-danger shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        GAMING WALLET</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Balance</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.gaming_wallet).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-2x gray"><GiCoins /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-warning shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        STAKING WALLET</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Balance</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.staking_wallet).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas  fa-2x gray"><GiReceiveMoney /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-info shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        TOTAL REWARD</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Net Profit</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.total_reward).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-dollar-sign fa-2x gray"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-primary shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        SOI REWARD</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Net Profit</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.roi_bonus).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-2x gray"><GiMoneyStack /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-dark shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        MATCHING REWARD</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Net Profit</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.binary_bonus).toFixed(2) || '0.00'} </div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-2x gray"><FaAward /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-primary shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        REFERRAL REWARD</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Net Profit</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.sponsor_bonus).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-2x gray"><TbCertificate /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-md-6 mb-4 ">
                                                    <div className="card border-left-secondary shadow h-100 py-2 ">
                                                        <div className="card-body box2s">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        KICK START REWARD</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Net Profit</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.kickstarter_bonus).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-wallet fa-2x gray"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-success shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        LIQUIDITY POOL REWARD</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Total Profitt</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.liquidity_bonus).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas  fa-2x gray"><GiTakeMyMoney /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-danger shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        LAST PACKAGE</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Total</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{(users?.last_package_name)}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-2x gray"><BsFillGiftFill /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-md-6 mb-4">
                                                    <div className="card border-left-warning shadow h-100 py-2 box2s">
                                                        <div className="card-body">
                                                            <div className="row no-gutters align-items-center">
                                                                <div className="col mr-2 mb-1">
                                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                                        TOKEN PRICE</div>
                                                                    <div className="h6 text-xs mb-0 font-weight-bold text-gray-600">Total</div>
                                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">${parseFloat(users?.tokenPrice).toFixed(2) || '0.00'}</div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <i className="fas fa-2x gray"><GiCoins /></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* // card2  Area Start from Here */}
                                    <div>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <Card>
                                                        <Card.Body className="bg-dark text-light">
                                                            <i className="float-right fas fa-2x gray"><FaUserAlt /></i>
                                                            <Card.Title>Username</Card.Title>
                                                            <Card.Subtitle className="mb-2">{(all_data?.username) || " "}</Card.Subtitle>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <Card>
                                                        <Card.Body className="bg-dark text-light">
                                                            <i className="float-right fas fa-2x gray"><MdDateRange /></i>
                                                            <Card.Title>First Subscription Date</Card.Title>
                                                            <Card.Subtitle className="mb-2">{(users?.subscription_date) || " "}</Card.Subtitle>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <Card>
                                                        <Card.Body className="bg-dark text-light">
                                                            <i className="float-right fas fa-2x gray"><FaRegMoneyBillAlt /></i>
                                                            <Card.Title>Total Subscription Amount</Card.Title>
                                                            <Card.Subtitle className="mb-2">$ {parseInt(users?.total_subscription).toFixed(2) || '0.00'}</Card.Subtitle>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <Card>
                                                        <Card.Body className="bg-dark text-light">
                                                            <i className="float-right fas fa-2x gray"><GiCutDiamond /></i>
                                                            <Card.Title>Total Referrals</Card.Title>
                                                            <Card.Subtitle className="mb-2">{(users?.total_referrals) || '0'}</Card.Subtitle>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <Card>
                                                        <Card.Body className="bg-dark text-light">
                                                            <i className="float-right fas fa-2x gray"><AiOutlineTeam /></i>
                                                            <Card.Title>Total Team</Card.Title>
                                                            <Card.Subtitle className="mb-2">{(users?.total_team) || "0"}</Card.Subtitle>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <Card>
                                                        <Card.Body className="bg-dark text-light">
                                                            <i className="float-right fas fa-2x gray"><BsFillArrowLeftSquareFill /></i>
                                                            <Card.Title>Left Team</Card.Title>
                                                            <Card.Subtitle className="mb-2">{(users?.total_leftteam) || "0"}</Card.Subtitle>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <Card>
                                                        <Card.Body className="bg-dark text-light">
                                                            <i className="float-right fas fa-2x gray"><BsFillArrowRightSquareFill /></i>
                                                            <Card.Title>Right Team</Card.Title>
                                                            <Card.Subtitle className="mb-2">{(users?.total_rightteam) || "0"}</Card.Subtitle>

                                                        </Card.Body>
                                                    </Card>
                                                </div>

                                            </div>
                                        </div>

                                        {all_data?.username === "admin" &&
                                            <div className="container-fluid mt-3 mb-3 ">
                                                <div className="row ">
                                                    <div className="col-12 col-md-6  ">
                                                        <div className="card ">
                                                            <div className="card-header bg-dark text-white">
                                                                SOI Percentage
                                                            </div>
                                                            <div className="card-body">
                                                                <PieChart
                                                                    paddingAngle={0}
                                                                    labelStyle={{
                                                                        fontWeight: 700,
                                                                        fontSize: "0.85rem",
                                                                        fill: "var(--Dark-01)"
                                                                    }}
                                                                    labelPosition={0}
                                                                    lineWidth={60}
                                                                    label={({ dataEntry }) => {
                                                                        if (dataEntry.title === "Completed") {
                                                                            return Math.round(dataEntry.value) + "% ";
                                                                        }
                                                                    }}
                                                                    viewBoxSize={[200, 200]}
                                                                    center={[100, 80]}
                                                                    data={chartData}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <div className="card">
                                                            <div className="card-header bg-dark text-white">
                                                                Total Income Percentage
                                                            </div>
                                                            <div className="card-body">
                                                                <PieChart
                                                                    paddingAngle={0}
                                                                    labelStyle={{
                                                                        fontWeight: 700,
                                                                        fontSize: "0.85rem",
                                                                        fill: "var(--Dark-01)"
                                                                    }}
                                                                    labelPosition={0}
                                                                    lineWidth={60}
                                                                    label={({ dataEntry }) => {
                                                                        if (dataEntry.title === "Completed") {
                                                                            return Math.round(dataEntry.value) + "% ";
                                                                        }
                                                                    }}
                                                                    viewBoxSize={[200, 200]}
                                                                    center={[100, 80]}
                                                                    data={chartData}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }




                                        <ReferralLink
                                            link={`${(users?.referral_link)}&leg=left`}
                                            link2={`${(users?.referral_link)}&leg=right`}
                                        />
                                    </div>

                                    <footer className="sticky-footer bg-white">
                                        <div className="container my-auto">
                                            <div className="copyright text-center my-auto">
                                                <span>Copyright &copy; 2023. All Rights Reserved</span>
                                            </div>
                                        </div>
                                    </footer>
                                </div>
                            </div>

                        )
                }

                <div className="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                            <div className="modal-footer">
                                <button className="btn btn-dark" type="button" data-dismiss="modal">Cancel</button>
                                <a className="btn btn-dark" href="/login">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Dashboard1;



